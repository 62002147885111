import { useEffect, useRef } from "react";

const useGoogleIMA = (callback) => {
  const retryCount = useRef(0); // Persist retry count across re-renders
  const maxRetries = 3;
  const isCancelled = useRef(false); // Persist cancellation state

  useEffect(() => {
    const loadScript = (url) => {
      return new Promise((resolve, reject) => {
        if (document.querySelector(`script[src="${url}"]`)) {
          // console.log("Google IMA SDK already loaded");
          resolve();
          return;
        }

        const script = document.createElement("script");
        script.src = url;
        script.async = true;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Failed to load script: ${url}`));
        document.head.appendChild(script);
      });
    };

    const initializeSDK = async () => {
      if (isCancelled.current) return;

      try {
        await loadScript("https://imasdk.googleapis.com/js/sdkloader/ima3.js");

        setTimeout(() => {
          if (window.google && window.google.ima && window.google.ima.AdDisplayContainer) {
            // console.log("Google IMA SDK initialized successfully");
            if (typeof callback === "function") {
              callback(); // Run the callback after SDK initialization
            }
          } else {
            console.warn("Google IMA SDK loaded but not fully initialized.");
          }
        }, 1000);
      } catch (error) {
        console.error("Error loading Google IMA SDK:", error);

        if (retryCount.current < maxRetries) {
          retryCount.current += 1;
          // console.log(`Retrying IMA SDK... Attempt ${retryCount.current}`);
          setTimeout(initializeSDK, 2000); // Retry after 2 seconds
        } else {
          console.error("Max retries reached. Failed to initialize IMA SDK.");
        }
      }
    };

    initializeSDK();

    return () => {
      isCancelled.current = true;
    };
  }, [callback]);
};

export default useGoogleIMA;
