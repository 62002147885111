import React, { useCallback, useEffect, useState } from "react";
import { FaAngleDoubleRight, FaPlay } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import { SkeletonLoader } from "../pages/skeletonLoad/skeletonthumbnail";
import { useDispatch, useSelector } from "react-redux";
// import { getAnalytics,logEvent  } from 'firebase/analytics';
import {
  setCatlog,
  setContent,
  setSelectedThumbnail,
  setTrayName,
} from "../Redux/Slice/showSlice";
import { acceptedMediaTypes } from "../services/config";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Loader from "../Componets/loader";
import { Helmet } from "react-helmet";
import { setMainTraynamedata, setThumbnailposition, setTraynamedata } from "../Redux/Slice/navlinkcallSlice";
import { guestuserid } from "../App";
import { setThroughSeach } from "../Redux/Slice/throughSearchSlice";

export const useComponentUtils = (
  catalogListData,
  hasMore,
  setHasMore,
  setLoading,
  loading,
  setCatalogListData,
  innerCatalogListData,
  setInnerCatalogListData,
  homePageData
) => {

  // console.log("sdsdsdaaaa", homePageData)
  const { innerPagesData } = useSelector((state) => state.inner_page_data);
  const verticaldefultimage = "/images/horizontaldefault.jpg";
  const horizontaldefultimage = "/images/verticaldefault.jpg";
  const etv_exclusive_banner_image = "/images/etv-exclusive_banner.png";
  const tv_shows_banner_image = "/images/tv_shows_banner.png";
  const top10_image = "/images/top10default.jpg";
  const twoGridDefault = "/images/twogridimage.jpg";
  const singleGridDefault = "/images/singlegriddefault.jpg";
  const nonvegicon = "/images/nonveg.png";
  const vegicon = "/images/veg.png";


  const dispatch = useDispatch();
  const regionsData = useSelector((state) => state.region.regionsData);

  const [imageLoadedArray, setImageLoadedArray] = useState(
    new Array(catalogListData?.length).fill(false)
  );

  const navigate = useNavigate();
  let isSliding = false;

  let gotoContentDetails = (link, data) => {


    const dynamicUrl = data?.seo_url;
    const ourseourl = dynamicUrl?.startsWith("/")
      ? dynamicUrl
      : `/${dynamicUrl}`;
    navigate(ourseourl);
    const thumbnail = data?.thumbnails?.web_banner?.url;
    dispatch(setSelectedThumbnail(thumbnail));
    dispatch(setCatlog({ catlogId: data.catalog_id }));
    dispatch(setContent({ contentId: data.content_id }));
    localStorage.setItem("selectedCatelog", data.catalog_id);
    localStorage.setItem("selectedContent", data.content_id);
    localStorage.setItem("selectedVideoThumb", thumbnail);
  };

  const loginStatus = localStorage.getItem("login_status")
  const getuserid = localStorage.getItem("user_id")
  function getPageName() {
    const path = window.location.pathname;
    const parts = path.split('/'); // Split path by '/'
    return parts[1] ?? "home"; // Return the second part after localhost/
}
const param1Value = getPageName();
const sourcetab = localStorage.getItem("selectedMenuItem");
  const itemSelected = (index,listItem) => {
    // console.log("listItemsss", listItem)
    localStorage.setItem("traynamedata", listItem.display_title)
    dispatch(setTraynamedata({ traynamedata:  listItem}));

    if (window && window.sdk && window.sdk.trackEvent) {
      window.sdk.trackEvent("artwork_tapped", {
        source:homePageData?.display_title?.toLowerCase(),
        video_name:listItem.title,
        tray_name: listItem.display_title || "NA",
        platform: "web",
        country: regionsData?.country_name,
        event_time: new Date().toISOString().slice(0, 19),
        event_id: 21,
        city: regionsData?.city_name,
        u_id:
        loginStatus === "true"
        ? getuserid
        : guestuserid,
        content_type: listItem.media_type ? listItem.media_type : 'NA',
        position_within_tray: index + 1,
        artwork_type: listItem.media_type ? listItem.media_type : 'NA',
   });

    } else {
      console.error('Analytics SDK is not initialized yet.');
      // Handle initialization state or queue events for later
    }
  
   

 };

 const selectedTrayItem = (index,listItem, mainItem) => {
  // console.log("listItemsss", listItem)
  // console.log("indexsss", index)
  // console.log("mainItemssss", mainItem)
  dispatch(setThroughSeach({ throughSeach: false }));
  localStorage.setItem("selectedItem", mainItem?.display_title)
  dispatch(setTraynamedata({ traynamedata:  listItem}));
  dispatch(setMainTraynamedata({ maintraynamedata:  catalogListData[0]}));

  if (window && window.sdk && window.sdk.trackEvent) {
    window.sdk.trackEvent("artwork_tapped", {
      source:homePageData?.display_title?.toLowerCase(),
      video_name:listItem?.title,
      tray_name: mainItem?.display_title?.toLowerCase() || "NA",
      platform: "web",
      city: regionsData?.city_name,
      event_id: 21,
      country: regionsData?.country_name,
      event_time: new Date().toISOString().slice(0, 19),
      u_id:
      loginStatus === "true"
      ? getuserid
      : guestuserid,
      content_type: listItem?.media_type || 'NA',
      position_within_tray: index + 1,
      artwork_type: listItem?.media_type || 'NA',
 });

  } else {
    console.error('Analytics SDK is not initialized yet.');
    // Handle initialization state or queue events for later
  }

 

};
  const handleImageLoad = (index) => {
    setImageLoadedArray((prevState) => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
  };


  const SamplePrevArrow = (props) => {
    const { className, onClick } = props
    return (
        <div 
            className={className}
            // className={`arrow ${className}`}
            onClick={onClick}
            style={{ cursor: "pointer" }} 
        >
           <GoChevronLeft />
            {/* <FaChevronLeft className='arrows' style={{ fontWeight: 100, background: 'transparent'}} /> */}
        </div>
    );
}

const SampleNextArrow = (props) => {
  const { className, onClick } = props
  return (
      <div 
          className={className}
          // className={`arrow ${className}`}
          onClick={onClick}
          style={{ cursor: "pointer" }} 
      >
        <GoChevronRight  />

          {/* <FaChevronRight className='arrows' style={{ fontWeight: 100, background: 'transparent' }} /> */}
      </div>
  );
}

  const generateVerticalModel = (item) => {
    let verticalmodel = {
      dots: false,
      infinite: false,
      speed: 500,
      lazyload: "anticipated",
      autoplay: false,
      delay: 3000,
      slidestoshow: 9,
      slidestoscroll: 4,
      nextarrow: <SampleNextArrow />,
      prevarrow: <SamplePrevArrow />,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidestoshow: 4,
            slidestoscroll: 1,
            infinite: false,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidestoshow: getSlidesToShow(item),
            slidestoscroll: 1,
            infinite: false,
            dots: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidestoshow: 3,
            slidestoscroll: 1,
            infinite: false,
            dots: false,
          },
        },
      ],
    };
    return verticalmodel;
  };


  const generateHorizontalModel = (item) => {
    let horizontalmodel = {
      dots: false,
      infinite: false,
      speed: 500,
       lazyload: "anticipated",
      // swipe: false,
      autoplay: false,
      centermode: false,
      delay: 3000,
      slidestoshow: 6.5,
      slidestoscroll: 3,
      initialSlide: 0,
      nextarrow: <SampleNextArrow  />,
      prevarrow: <SamplePrevArrow/>,
      //   nextArrow: <SampleNextArrow wrapperClassName="arrow-wrapper" />,
      // prevarrow: <SamplePrevArrow wrapperClassName="arrow-wrapper" />,
      // prevarrow: null,
      
    
  
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidestoshow: 3.5,
            slidestoscroll: 3,
            dots: false,
            infinite: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidestoshow: 2,
            slidestoscroll: 2,
            initialSlide: 2,
            dots: false,
            infinite: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidestoshow: getSlidesToShow(item),
            slidestoscroll: 2,
            initialSlide: 2,
            dots: false,
            infinite: false,
            
          },
        },
      ],
    };
    return horizontalmodel;
  };

  

  function getSlidesToShow(data) {
    return data && data.total_items_count >= 2 ? 3 : 1;
  }


  const generateBigModel = (item) => {
    let bigmodel = {
      dots: false,
      infinite: false,
      speed: 500,
       lazyload: "anticipated",
      autoplay: false,
      delay: 3000,
      slidestoshow: 6.5,
      slidestoscroll: 4,
      nextarrow: <SampleNextArrow  />,
      prevarrow: <SamplePrevArrow/>,
  
  
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidestoshow: 3.8,
            slidestoscroll: 3,
            dots: false,
            infinite: false,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidestoshow: 2.8,
            slidestoscroll: 2,
            dots: false,
            infinite: false,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidestoshow: getSlidesToShow(item),
            slidestoscroll: 2,
            dots: false,
            infinite: false,
          }
        }
      ]
  
  
    };
    return bigmodel;
  };


  const getSingelgridModel = (item) => {
    var singelgrid = {
      dots: false,
      infinite: false,
      speed: 500,
       lazyload: "anticipated",
      autoplay: false,
      delay: 3000,
      slidestoshow: 1,
      slidestoscroll: 1,
      // swipe: false,
      nextarrow: <SampleNextArrow  />,
      prevarrow: <SamplePrevArrow />,
  
  
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidestoshow: 3.8,
            slidestoscroll: 3,
            dots: false,
            infinite: false,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidestoshow: 2.8,
            slidestoscroll: 2,
            dots: false,
            infinite: false,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidestoshow: getSlidesToShow(item),
            slidestoscroll: 2,
            dots: false,
            infinite: false,
          }
        }
      ]
  
  
    };
    return singelgrid;
  };

   

  var twogrid = {
    dots: false,
    // asNavFor: '.twogrid',
    infinite: false,
    speed: 500,
     lazyload: "anticipated",
    autoplay: false,
    delay: 3000,
    slidestoshow: 2,
    slidestoscroll: 1,
    nextarrow: <SampleNextArrow  />,
    prevarrow: <SamplePrevArrow />,


    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidestoshow: 2,
          slidestoscroll: 3,
          dots: false,
          infinite: false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidestoshow: 2,
          slidestoscroll: 2,
          dots: false,
          infinite: false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidestoshow: 1.3,
          slidestoscroll: 2,
          dots: false,
          infinite: false,
        }
      }
    ]


  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);

  useEffect(() => {
    const handleResize = () => {
        setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
}, []);


const [isFullscreen, setIsFullscreen] = useState(false);

useEffect(() => {
  let initialWidth = window.innerWidth;
  let initialHeight = window.innerHeight;

  const handleResize = () => {
    const currentWidth = window.innerWidth;
    const currentHeight = window.innerHeight;

    // Only reload if the width has changed, the page is not in fullscreen mode,
    // and the change is not triggered by exiting fullscreen mode.
    if (!isFullscreen && currentWidth !== initialWidth) {
      window.location.reload();
    }

    // Update the initial width and height after resize to avoid repeated reloads
    initialWidth = currentWidth;
    initialHeight = currentHeight;
  };

  const handleFullscreenChange = () => {
    // Check if any element is in fullscreen mode
    const fullscreenStatus = !!document.fullscreenElement;
    setIsFullscreen(fullscreenStatus);

    // Update initial dimensions when entering or exiting fullscreen mode to prevent reloads
    if (fullscreenStatus) {
      initialWidth = window.innerWidth;
      initialHeight = window.innerHeight;
    }
  };

  window.addEventListener('resize', handleResize);
  document.addEventListener('fullscreenchange', handleFullscreenChange);

  return () => {
    window.removeEventListener('resize', handleResize);
    document.removeEventListener('fullscreenchange', handleFullscreenChange);
  };
}, [isFullscreen]);

  

const renderSliderContentWithLayoutType = (
  layoutType,
  title,
  layoutStructure,
  thumbnailType
) => {
  // Deduplicate catalogListData based on a unique identifier
  const uniqueCatalogListData = catalogListData.reduce((uniqueItems, currentItem) => {
    const isDuplicate = uniqueItems.some(
      (item) =>
        item.web_layout_type === currentItem.web_layout_type &&
        item.display_title === currentItem.display_title &&
        item.seo_url === currentItem.seo_url
    );
    if (!isDuplicate) {
      uniqueItems.push(currentItem);
    }
    return uniqueItems;
  }, []);

  // Map over the deduplicated data
  return uniqueCatalogListData.map((item, index) => {
    let sliderProps = {};
    let defultLocalimage = "";
    let styleWidth = "181";
    let styleHeight = "102";
    let dynamicpadding = 5;

    // Configure styles and properties based on layoutType
    if (
      layoutType === "movie_poster" ||
      layoutType === "episode"
     // horizontal
    ) {
      styleWidth = "181";  
      styleHeight = "102";dynamicpadding = 5;
    } else if(layoutType === "tv_shows") { // vertical
      styleWidth = "181";  
      styleHeight = "102";dynamicpadding = 5;
    }

    if (layoutType === "etv-exclusive_banner") {
      sliderProps = twogrid;
      defultLocalimage = twoGridDefault;
    } else if (layoutType === "tv_shows_banner") {
      sliderProps = getSingelgridModel(item);
      defultLocalimage = singleGridDefault;
    } else if (layoutType === "top10" || layoutType === "movie" || layoutType === "movies") {
      sliderProps = generateBigModel(item);
      defultLocalimage = top10_image;
    } else if (layoutType === "tv_shows" || layoutType === 'shows' || layoutType === 'show') {
      sliderProps = generateVerticalModel(item);
      defultLocalimage = horizontaldefultimage;
    } else if (
      layoutType === "latest_episode" ||
      layoutType === "short" ||
      layoutType === "episode" ||
      layoutType === "mini_movie_poster" ||
      layoutType === "movie_poster" ||
      layoutType === "videos" ||
      layoutType === "promo" ||
      layoutType === "mini_clips" ||
      layoutType === "recipe" ||
      layoutType === "movie_poster" 
    ) {
      sliderProps =  generateHorizontalModel(item);
      defultLocalimage = verticaldefultimage;
    } else{
      sliderProps =  generateVerticalModel(item);
      defultLocalimage = horizontaldefultimage;
    }
    let no_of_skeltens = 0;
    if (item.total_items_count >= 6) {
      no_of_skeltens = 8;
    } else {
      no_of_skeltens = item.total_items_count;
    }
    // console.log(item.statusIsSketan);

    let slidesPerView; 
  
      if (layoutType === "tv_shows_banner") {
        slidesPerView = 1;
      }else if (layoutType === "top10" || layoutType === "movie" || layoutType === "movies") {
        slidesPerView = isMobile ? 2 : 6.5;
      } else if (layoutType === "tv_shows" || layoutType === 'shows' || layoutType === 'show') {
        slidesPerView = isMobile ? 3 : 9;
      } else if (layoutType === "etv-exclusive_banner") {
        slidesPerView = isMobile ? 1 : 2;
      } else if (["latest_episode", "short", "episode", "mini_movie_poster", "movie_poster", "videos", "promo", "mini_clips", "recipe"].includes(layoutType)) {
        slidesPerView = isMobile ? 2 : 6.5;
      } else{
        slidesPerView = isMobile ? 2 : 6.5;
      }

      let slidespergroup;
      
      if(layoutType === "etv-exclusive_banner"){
        slidespergroup = !isMobile ? 2 : 1
      }else if(layoutType === "tv_shows_banner"){
        slidespergroup = !isMobile ? 1 : 1
      }else{
        slidespergroup = !isMobile ? 5 : 2
      }

    return (
      <div key={index}>
        {item?.web_layout_type === layoutType &&
          item?.display_title === title && (
            <Swiper
            navigation={!isMobile ? true : false}  modules={[Pagination, Navigation]}
              slidesPerView={slidesPerView}
              speed={isMobile ? 90 : 1000}
              slidesPerGroup={slidespergroup}
              spaceBetween={0}
              onSlideChange={() => console.log('slide change')}
              onSwiper={(swiper) => SampleNextArrow}
              breakpoints={{
                "640": {
                  slidesPerView: 3,
                  spaceBetween: 10
                },
                "768": {
                  slidesPerView: slidesPerView,
                  spaceBetween: 10
                },
                "1024": {
                  slidesPerView: slidesPerView,
                  spaceBetween: 0
                }
              }}
            >
              {item?.catalog_list_items?.map((innerlist, innerIndex) => {
                const imageSource =
                  (layoutType === "etv-exclusive_banner" || layoutType === "tv_shows_banner") &&
                  innerlist?.thumbnails?.[thumbnailType]
                    ? innerlist.thumbnails[thumbnailType].url
                    : innerlist?.thumbnails?.[thumbnailType]?.url ||
                      innerlist?.list_item_object?.banner_image ||
                      defultLocalimage;
                      const maxCharacters = 24;

                      let displayTitle =
                        innerlist?.title?.length > maxCharacters
                          ? innerlist?.title?.substring(0, maxCharacters) +
                            "..."
                          : innerlist?.title;
                      if (thumbnailType === null) {
                        if (
                          innerlist &&
                          innerlist.thumbnails &&
                          innerlist.thumbnails[thumbnailType]
                        ) {
                          innerlist.thumbnails[thumbnailType].url =
                            defultLocalimage;
                        }
                      }

                      let releaseDate = new Date(innerlist.release_date_string);
                      const day = releaseDate.getDate().toString().padStart(2, "0");
                      const year = (releaseDate.getFullYear() % 100).toString().padStart(2, "0");
                      const monthAbbreviation = releaseDate.toLocaleString("default", { month: "short" });
                      let formattedDate = `${day} ${monthAbbreviation} ${year}`;

                return (
                  <SwiperSlide key={innerIndex}>
                    <div
                      style={{ cursor: "pointer" }}
                      className="carousel-car continuewatch"
                      onClick={() => {
                        dispatch(setThumbnailposition({ thumbnailposition: innerIndex + 1 }));
                        dispatch(setTrayName({ layoutname: item }));
                        gotoContentDetails(innerlist.seo_url, innerlist);
                      }}
                    >
                      <div
                        className={`extra lock-icon-class ${
                          !(
                            layoutType === "tv_shows_banner" ||
                            layoutType === "etv-exclusive_banner"
                          ) && "cms-container cw-card"
                        } ${thumbnailType}`}
                      >
                         <>
                                <div className={`thumbnailMovieCard ${
                                !(
                                  layoutType === "tv_shows_banner" ||
                                  layoutType === "etv-exclusive_banner"
                                ) && "transfimage"
                              } `}>
                                 <img
  onClick={() => selectedTrayItem(innerIndex, innerlist, item)}
  src={imageLoadedArray[index] ? imageSource : defultLocalimage}
  className={`${title === 'Health & Fitness' ? 'healthlayout' : layoutType}`}
  width="100%"
  onLoad={() => handleImageLoad(index)}
  onError={(e) => {
    e.target.src = defultLocalimage; // Set default image if loading fails
    handleImageLoad(index); // Optionally set as loaded to prevent reloading
  }}
  loading={!imageLoadedArray[index] ? "eager" : "lazy"}
  alt={displayTitle || "Default Image"}
  />

{imageLoadedArray[index] && (
<>
{layoutType === "top10" && (
                                    <div className="top-show">
                                      <div className="top-show-icon">
                                        Top <span>{innerIndex + 1}</span>
                                      </div>
                                    </div>
                                  )}

                                 {/* Lock Position */}
      {innerlist?.access_control?.is_free === false && (
        <div className="lock-postion-class"></div>
      )}

      {/* Play Icon */}
      {innerlist?.media_type &&
        acceptedMediaTypes.includes(innerlist.media_type) && (
          <div className="iconcame">
            <FaPlay />
          </div>
        )}

</>
)
                    }
                                  

                                  
                                </div>

                                {imageLoadedArray[index] && (
                                  <>

{/* Display Title */}
{innerlist.show_meta_data === "yes" && innerlist?.media_type && (
<p className="displayTitle">
{displayTitle.length > 13
? `${displayTitle.substring(0, 13)}...`
: displayTitle}
{innerlist.release_date_string ? ` | ${formattedDate}` : ""}
</p>
)}

    {/* Veg or Non-Veg Icon */}
{(innerlist?.sub_genres?.includes("veg") ||
innerlist?.sub_genres?.includes("non_veg")) && (
<div className="FoodType">
<img
src={
innerlist?.sub_genres?.includes("veg") ? vegicon : nonvegicon
}
className={`${title === 'Health & Fitness' ? 'healthlayout' : layoutType}`}
loading="lazy"
alt=""
/>
</div>
)}
                                  </>
                                )}
                              </>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
      </div>
    );
  });
};


    const renderCategoryBlockWithLayoutType = (
      layoutType,
      title,
      layoutStructure,
      thumbnailType
    ) => {
      // Filter items based on layoutType, title, and ensure they have catalog_list_items
      const filteredItems = catalogListData
        .filter(
          (item) =>
            item.web_layout_type === layoutType &&
            item.display_title === title &&
            item.catalog_list_items?.length > 0
        )
        .reduce((uniqueItems, currentItem) => {
          // Use a unique identifier (like SEO URL or friendly ID) to ensure no duplicates
          const isDuplicate = uniqueItems.some(
            (item) =>
              item.friendly_id === currentItem.friendly_id &&
              item.seo_url === currentItem.seo_url
          );
          if (!isDuplicate) {
            uniqueItems.push(currentItem);
          }
          return uniqueItems;
        }, []);
    
      // SEO metadata
      const metadescription =
        innerPagesData?.seo_description ||
        'Watch your favourite etv telugu shows jabardasth, dhee, alitho saradaga, cash, sridevi drama company & get a super hit collection of all telugu serials on etv win. Now watch the gold collection of all telugu movies on etv win.';
      const metatitle = innerPagesData?.seo_title || 'ETV Win';
      const metakeywords = innerPagesData?.seo_keyword || 'ETV Win';
    
      return (
        <>
          <Helmet>
            <meta name="title" content={metatitle} />
            <meta name="description" content={metadescription} />
            <meta name="keywords" content={metakeywords} />
          </Helmet>
          {filteredItems.length > 0 &&
            filteredItems.map((item, index) => (
              <div key={item.friendly_id || item.seo_url || index} className="first-block">
                <div className="first-slider">
                  <div className="title-bar">
                    <Container fluid className="pading-left-right-class">
                      <Row>
                        <Col>
                          <div className="heading-font-size">
                            <h2>{title}</h2>
                          </div>
                        </Col>
                        <Col
                          xs="auto"
                          style={{ textAlign: 'end', padding: '0px' }}
                        >
                          {item.catalog_list_items?.length >= 9 && (
                            <Link
                              to={
                                layoutType === 'recipe'
                                  ? item.seo_url.startsWith('/')
                                    ? item.seo_url
                                    : `/${item.seo_url}`
                                  : item.friendly_id.startsWith('/')
                                  ? item.friendly_id
                                  : `/${item.friendly_id}`
                              }
                              className="more-heading"
                            >
                              More
                              <span>
                                <FaAngleDoubleRight />
                              </span>
                            </Link>
                          )}
                        </Col>
                      </Row>
                    </Container>
                  </div>
                  <Container fluid className="left-padding-grid">
                    <div
                      className={`smooth-slider ${
                        title === 'Health & Fitness'
                          ? 'healthlayout'
                          : layoutType
                      }`}
                    >
                      {renderSliderContentWithLayoutType(
                        layoutType,
                        title,
                        layoutStructure,
                        thumbnailType
                      )}
                    </div>
                  </Container>
                </div>
              </div>
            ))}
        </>
      );
    };
    

  return {
    loading,
    setLoading,
    catalogListData,
    setCatalogListData,
    innerCatalogListData,
    setInnerCatalogListData,
    hasMore,
    setHasMore,
    navigate,
    renderSliderContentWithLayoutType,
    renderCategoryBlockWithLayoutType,
  };
};
