import React, { lazy, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
const LazyLoadPassword = lazy(() => import("../Register/changepassword"));
const LazyLoadMyTransactions = lazy(() => import("../MyTransactions/MyTransactions"));
const LazyLoadSubscriptionplan = lazy(() => import("../Subscription-Plans/Subscriptionplan"));
const LazyLoadMyProfile = lazy(() => import("./MyProfile"));
const LazyLoadCancelAutopay = lazy(() => import("../Subscription-Plans/cancelAutoRenew"));


export default function ProfileInfo() {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get('tab'); 
    const isUserLoggedInStatus = localStorage.getItem("login_status");

  // console.log("skksksks", location)

  
  return (
    <>
     {tab === "info" && <LazyLoadMyProfile />}
     {tab === "subscription" && <LazyLoadSubscriptionplan />}
     {tab === "transactions" && <LazyLoadMyTransactions />} 
     {tab === "password" && <LazyLoadPassword />} 
     {tab === "cancelautopay" && <LazyLoadCancelAutopay />} 
    </>
  )
}
