/* global google */
// Prevents ESLint errors for 'google' not found.

var videoElement;
var adsLoaded = false;
var adDisplayContainer;
var adsLoader;
var adsManager;
var adContainer;
var isAdPlaying = false;
var muteButton = null;
var isMuted = false; // Tracks the mute state
var adInitialized = false;
var isIOSDevice = false;
var retryCount = 0;
const maxRetries = 3;
var adsRequest;
// Global variables
var videoElement;   // Stores the main video element
var adVideoElement; // Stores the ad's video element
var adContainer;    // Stores the ad container
var adTimer;        // Stores the ad timer UI
var adTimerInterval; // Stores the timer interval
var isFullScreen = false;
var midRollCalled;



const adInitializedEvent = new Event("adInitialized");

export const initializeAds = (videoElem, container, adTagUrl, midRoll, isIOS) => {
  resetAds(); // 🔄 Reset ads for new content
  if (window.location.hostname === "localhost") {
    adTagUrl = "https://pubads.g.doubleclick.net/gampad/ads?iu=/23111733658/AM_IM_AT_FT_TO_WE_TI/WEBTIZEN_FU&description_url=https%3A%2F%2Fwww.etvwin.com&tfcd=0&npa=0&sz=640x480&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator=&vad_type=linear&ad_rule=1 ";
  }

  return new Promise((resolve, reject) => {
    if (!window.google || !window.google.ima) {
      reject(new Error("Google IMA SDK not loaded"));
      console.error("❌ IMA SDK not loaded");
      return;
    }

    // console.log("✅ IMA SDK Loaded");

    videoElement = videoElem;
    adContainer = container;
    isIOSDevice = isIOS;

    if (!videoElement) {
      reject(new Error("❌ Video element is not defined"));
      return;
    }

    google.ima.settings.setVpaidMode(google.ima.ImaSdkSettings.VpaidMode.ENABLED);
    google.ima.settings.setDisableCustomPlaybackForIOS10Plus(true);

    adDisplayContainer = new google.ima.AdDisplayContainer(adContainer, videoElement);
    adsLoader = new google.ima.AdsLoader(adDisplayContainer);

    adsLoader.addEventListener(
      google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
      (event) => {
        // console.log("✅ Ads Manager Loaded Event Triggered");
        onAdsManagerLoaded(event);
        resolve();
      }
    );

    adsLoader.addEventListener(google.ima.AdErrorEvent.Type.AD_ERROR, (event) => {
      console.error("🚨 Ad Error Event:", event.getError().toString());
      reject(new Error(`Ad Error: ${event.getError().toString()}`));
    });

    adsRequest = new google.ima.AdsRequest();
    adsRequest.adTagUrl = adTagUrl;
    adsRequest.linearAdSlotWidth = videoElement.clientWidth;
    adsRequest.linearAdSlotHeight = videoElement.clientHeight;
    adsRequest.nonLinearAdSlotWidth = videoElement.clientWidth;
    adsRequest.nonLinearAdSlotHeight = videoElement.clientHeight;

    if (isIOSDevice) {
      adsRequest.setAdWillPlayMuted(true);
    }

    adsLoader.requestAds(adsRequest);
    adInitialized = true;
    window.dispatchEvent(adInitializedEvent);
  });
};
const adPlayingEvent = new Event("adPlaying");
const adStoppedEvent = new Event("adStopped");


const onContentComplete = () => {
  if (adsLoader) {
    adsLoader.contentComplete();
  }
};

const onWindowResize = () => {
  if (adsManager) {
    const width = videoElement.clientWidth;
    const height = videoElement.clientHeight;
    adsManager.resize(width, height, google.ima.ViewMode.FULLSCREEN);
  }
};

async function onContentPauseRequested() {
  if (videoElement && adContainer) {
    adContainer.style.backgroundColor = "#000000";
    adContainer.style.zIndex = 3;
    isAdPlaying = true;

    const controls = document.querySelector(".plyr__controls");
    const overlaidControls = document.querySelector(".plyr_control--overlaid");
    if (controls) {
      controls.style.display = "none";
      controls.style.opacity = 0;
    }
    if (overlaidControls) {
      overlaidControls.style.display = "none";
      overlaidControls.style.opacity = 0;
    }

    window.dispatchEvent(adPlayingEvent);

    try {
      await videoElement.pause();
    } catch (error) {
      console.error("Error pausing video:", error);
    }
  }
}

async function onContentResumeRequested() {
  if (videoElement) {
    adContainer.style.zIndex = -1;
    isAdPlaying = false;

    const controls = document.querySelector(".plyr__controls");
    const overlaidControls = document.querySelector(".plyr_control--overlaid");
    if (controls) {
      controls.style.display = "flex";
      controls.style.opacity = 1;
    }
    if (overlaidControls) {
      overlaidControls.style.display = "flex";
      overlaidControls.style.opacity = 1;
    }

    window.dispatchEvent(adStoppedEvent);

    try {
      if (videoElement.muted && isIOSDevice) {
        videoElement.muted = false;
      }
      await videoElement.play();
    } catch (error) {
      console.error("Error resuming video playback:", error);
    }
  }
}


export const resumeAdPlayback = () => {
  if (adsManager) {
    // console.log("🔹 Resuming Ad in PiP Mode");
    adsManager.resume();
  } else {
    console.error("❌ Cannot resume Ad: adsManager is NULL");
  }
};

export const pauseAdPlayback = () => {
  if (adsManager) {
    // console.log("🔹 Pausing Ad in PiP Mode");
    adsManager.pause();
  } else {
    console.error("❌ Cannot pause Ad: adsManager is NULL");
  }
};




function resizeAds() {
  if (adsManager) {
    isFullScreen =
      document.fullscreenElement === videoElement ||
      document.webkitFullscreenElement === videoElement ||
      document.mozFullScreenElement === videoElement ||
      document.msFullscreenElement === videoElement ||
      (isIOSDevice &&
        videoElement.clientHeight === window.innerHeight &&
        videoElement.clientWidth === window.innerWidth);
    if (isFullScreen) {
      adsManager.resize(
        videoElement.clientWidth,
        videoElement.clientHeight,
        google.ima.ViewMode.FULLSCREEN
      );
    } else {
      adsManager.resize(
        videoElement.clientWidth,
        videoElement.clientHeight,
        google.ima.ViewMode.NORMAL
      );
    }
  }
}

async function onAdsManagerLoaded(adsManagerLoadedEvent) {
  var adsRenderingSettings = new google.ima.AdsRenderingSettings();
  adsRenderingSettings.restoreCustomPlaybackStateOnAdBreakComplete = true;
  if (!isIOSDevice) {
    adsRenderingSettings.enablePreloading = true;
  }

  adsManager = adsManagerLoadedEvent.getAdsManager(videoElement, adsRenderingSettings);
  // console.log("📺 Ads Manager Object:", adsManager);
  resizeAds();
  
  if (
    videoElement &&
    adContainer &&
    adsManager &&
    adInitialized 
    // midRollCalled
  ) {
    loadAds(adsManagerLoadedEvent);
  }
  adsManager.addEventListener(
    google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED,
    onContentPauseRequested
  );
  adsManager.addEventListener(
    google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED,
    onContentResumeRequested
  );
  if (!adsManager) {
    console.error("❌ Ads Manager is NULL! Cannot proceed.");
    return;
  }

  const events = [
    google.ima.AdEvent.Type.LOADED,
    google.ima.AdEvent.Type.STARTED,
    google.ima.AdEvent.Type.COMPLETE,
    google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
    google.ima.AdEvent.Type.SKIPPED
  ];

  for (const eventType of events) {
    adsManager.addEventListener(eventType, onAdEvent);
  }


  // Prevent main video from resuming unexpectedly
function preventMainVideoResume(event) {
  if (isAdPlaying) {
    // console.log("⚠️ Preventing main video from resuming while ad is playing.");
    event.preventDefault();
    videoElement.play();
  }
}

adsManager.addEventListener(google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED, () => {
  // console.log("⏸️ Pausing main video for Ad...");
  isAdPlaying = true;

  // Prevent the main video from resuming while the ad is loading
  videoElement.pause();
  videoElement.removeEventListener("play", preventMainVideoResume);
  videoElement.addEventListener("play", preventMainVideoResume);
});

adsManager.addEventListener(google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED, () => {
  // console.log("▶️ Resuming main video after Ad...");
  isAdPlaying = false;

  // Delay resuming playback to ensure smooth transition
  setTimeout(() => {
    if (!isAdPlaying) {
      videoElement.play().catch((err) => console.error("🚨 Error resuming main video:", err));
    }
  }, 500);
});


  adsManager.addEventListener(google.ima.AdEvent.Type.AD_ERROR, onAdError);
  adsManager.addEventListener(google.ima.AdEvent.Type.LOADED, onAdLoaded);
  adsManager.addEventListener(google.ima.AdEvent.Type.STARTED, () => {
    // console.log("🎥 Ad Started Playing");

   
    ensureAdVisibility();
  });

  adsManager.addEventListener(google.ima.AdEvent.Type.ALL_ADS_COMPLETED, () => {
    // console.log("🏁 Ad Completed, resetting ads...");
    adsLoaded = false; // 🔄 Allows new ads to play
    hideAdContainer();
  });

  // ✅ Reset duration when an ad completes to avoid using old data


}



// export const loadAds = async () => {
//   if (adsLoaded) {
//     console.warn("⚠️ Ads already loaded. Skipping...");
//     return;
//   }

//   if (!videoElement || !adDisplayContainer) {
//     console.error("❌ Video element or ad display container is missing");
//     return;
//   }

//   if (!adInitialized) {
//     console.error("❌ Ads not initialized yet");
//     return;
//   }

//   try {
//     // console.log("🟢 Initializing Ad Display Container...");
//     adDisplayContainer.initialize();
//     // console.log("✅ Ad Display Container Initialized!");

//     setTimeout(async () => {
//       try {
//         if (adsManager && !adsLoaded) {
//           if (isFullScreen) {
//             await adsManager.init(
//               videoElement.clientWidth,
//               videoElement.clientHeight,
//               google.ima.ViewMode.FULLSCREEN
//             );
//           } else {
//             await adsManager.init(
//               videoElement.clientWidth,
//               videoElement.clientHeight,
//               google.ima.ViewMode.NORMAL
//             );
//           }
//         }
//         videoElement.pause();
//         await adsManager.start();
//         adsLoaded = true;
//       } catch (adError) {
//         console.error("AdsManager could not be started: ", adError);
//         await fallbackToVideoPlayback(); // Handles fallback gracefully
//       }
//     }, 500);


//     // setTimeout(async () => {
//     //   try {
//     //     // console.log("📏 Initializing Ads Manager...");
//     //     adsManager.init(videoElement.clientWidth, videoElement.clientHeight, google.ima.ViewMode.NORMAL);
//     //     // console.log("🚀 AdsManager Initialized!");

//     //     // console.log("🎬 Starting Ads...");
//     //     videoElement.pause(); // ✅ Pause main video before starting ads
//     //     adsManager.start();
//     //     // console.log("✅ AdsManager started successfully!");

        

//     //     adsLoaded = true; // ✅ Only set to true if `start()` succeeds


        
//     //   } catch (adError) {
//     //     console.error("⚠️ AdsManager Start Error:", adError);
//     //     adsLoaded = false;
//     //     fallbackToVideoPlayback();
//     //   }
//     // }, 500);
//   } catch (error) {
//     console.error("⚠️ Ad Display Container Initialization Failed:", error);
//     fallbackToVideoPlayback();
//   }
// };

// export const loadAds = async () => {
//   if (adsLoaded) return;

//   try {
//     adDisplayContainer.initialize();
//     setTimeout(async () => {
//       if (adsManager && !adsLoaded) {
//         const isPiPActive = !!document.pictureInPictureElement;
//         await adsManager.init(
//           videoElement.clientWidth,
//           videoElement.clientHeight,
//           isPiPActive ? google.ima.ViewMode.FULLSCREEN : google.ima.ViewMode.NORMAL
//         );
//         videoElement.pause();
//         await adsManager.start();
//         adsLoaded = true;

//         if (isPiPActive && adVideoElement) {
//           try {
//             await document.exitPictureInPicture();
//             await adVideoElement.requestPictureInPicture();
//             await adVideoElement.play();
//           } catch (err) {
//             console.error("PiP Error:", err);
//           }
//         }
//       }
//     }, 500);
//   } catch (error) {
//     console.error("Ad Load Error:", error);
//     await fallbackToVideoPlayback();
//   }
// };




let wasPiPEnabled = false; // Track whether PiP was enabled before ad starts

export const loadAds = async () => {
  if (adsLoaded) return;

  try {
    adDisplayContainer.initialize();

    setTimeout(async () => {
      if (!adsManager || adsLoaded) return;

      try {
        await adsManager.init(
          videoElement.clientWidth,
          videoElement.clientHeight,
          wasPiPEnabled ? google.ima.ViewMode.FULLSCREEN : google.ima.ViewMode.NORMAL
        );

        videoElement.pause();

        try {
          await adsManager.start();
          adsLoaded = true;
        } catch (adStartError) {
          // console.warn("⚠️ Ad Start Failed: Resuming Video Playback...", adStartError);
          await fallbackToVideoPlayback();
          return;
        }

        // ✅ Handle PiP Mode Activation
        document.addEventListener("enterpictureinpicture", async (event) => {
          // console.log("🎥 PiP Mode Activated");
          wasPiPEnabled = true; 

          try {
            if (isAdPlaying && adVideoElement && event.target === videoElement) {
              // console.log("🔄 Switching PiP to Ad Video...");
              await document.exitPictureInPicture();
              await adVideoElement.requestPictureInPicture();
              await adVideoElement.play();
            }
          } catch (error) {
            console.error("⚠️ Error switching PiP to Ad:", error);
          }
        });

        // ✅ Handle PiP Mode Exit
        document.addEventListener("leavepictureinpicture", async () => {
          // console.log("🚪 PiP Mode Exited");
          wasPiPEnabled = false;

          if (isAdPlaying && adVideoElement) {
            try {
              // console.log("📺 Resuming Ad in Main Player after PiP Exit...");
              await adVideoElement.play();
            } catch (error) {
              console.error("⚠️ Error resuming Ad after PiP exit:", error);
            }
          }
        });

        // ✅ Ensure that if an ad starts while PiP is enabled, it plays in PiP mode
        adsManager.addEventListener(google.ima.AdEvent.Type.STARTED, async (event) => {
          // console.log("📢 Ad Started... Checking PiP Mode");
          adDurationFromVAST = event.ad.getDuration(); 

          updateAdTimer(event.ad.getAdPodInfo().getAdPosition(), event.ad.getAdPodInfo().getTotalAds());

          if (wasPiPEnabled && adVideoElement) {
            // console.log("🔄 Moving Ad to PiP since user had PiP enabled");
            try {
              await adVideoElement.requestPictureInPicture();
              await adVideoElement.play();
            } catch (error) {
              console.error("⚠️ Failed to move Ad to PiP:", error);
            }
          }
        });

        // ✅ If the user skips an ad, the main video should resume in PiP mode
        adsManager.addEventListener(google.ima.AdEvent.Type.SKIPPED, async () => {
          // console.log("⏭️ Ad Skipped... Checking PiP Mode");
          clearInterval(adTimerInterval);
          adRemainingTime = null;

          if (wasPiPEnabled) {
            // console.log("🔄 Moving Main Video Back to PiP after Ad Skip");
            try {
              await videoElement.requestPictureInPicture();
              await videoElement.play();
            } catch (error) {
              console.error("⚠️ Failed to move Main Video back to PiP after Ad Skip:", error);
            }
          }
        });

        // ✅ If the ad completes, the main video should resume in PiP mode
        adsManager.addEventListener(google.ima.AdEvent.Type.COMPLETE, async () => {
          // console.log("🏁 Ad Completed... Checking PiP Mode");
          clearInterval(adTimerInterval);
          adRemainingTime = null;

          if (wasPiPEnabled) {
            // console.log("🔄 Moving Main Video Back to PiP");
            try {
              await videoElement.requestPictureInPicture();
              await videoElement.play();
            } catch (error) {
              console.error("⚠️ Failed to move Main Video back to PiP:", error);
            }
          }
        });

        // ✅ Prevent PiP from exiting when an ad starts
        videoElement.addEventListener("pause", async () => {
          if (wasPiPEnabled && isAdPlaying && document.pictureInPictureElement) {
            // console.log("🔄 Keeping PiP Active...");
            try {
              await videoElement.play();
              videoElement.pause(); // Fake play-pause to prevent exit
            } catch (error) {
              console.error("⚠️ Error keeping PiP active:", error);
            }
          }
        });

      } catch (adInitError) {
        // console.warn("⚠️ Ad Initialization Failed: Skipping Ads...", adInitError);
        await fallbackToVideoPlayback();
      }

    }, 500);

  } catch (error) {
    console.error("❌ Ad Load Error:", error);
    await fallbackToVideoPlayback();
  }
};








export const resetAds = () => {
  // console.log("🔄 Resetting Ads for new content...");

  if (adsManager) {
    adsManager.destroy();
    adsManager = null;
  }

  adsLoaded = false; // 🔄 Allow ads to load again
  adInitialized = false;
};

function ensureAdVisibility() {
  // console.log("📢 Ensuring Ad Visibility...");
  adContainer.style.display = "block";
  adContainer.style.opacity = "1";
  adContainer.style.zIndex = "1000";


  adContainer.style.position = "absolute";
  adContainer.style.width = "100%";
  adContainer.style.height = "100%";
  adContainer.style.top = 0;

  adContainer.style.left = 0;
  adContainer.style.display = "flex";
  adContainer.style.justifyContent = "center";
  adContainer.style.alignItems = "center";
}

function hideAdContainer() {
  // console.log("❌ Hiding Ad Container after ad completion.");
  adContainer.style.display = "none";
}

let adDurationFromVAST = null; // Store ad duration from VAST


function onAdLoaded(event) {
  // console.log("eventsjsj", event.ad.data.duration)
  // console.log("✅ Ad Loaded Successfully!", event);
  adDurationFromVAST = event.ad.getDuration()
  ensureAdVisibility();
}
const adFailedEvent = new Event("adFailed");

function onAdError(adErrorEvent) {
  const adError = adErrorEvent.getError();
  console.error("🚨 Ad Error:", adError.toString());
  window.dispatchEvent(adFailedEvent);

  const errorCode = adError.getErrorCode();

  if (
    errorCode === google.ima.AdError.ErrorCode.VAST_MEDIA_LOAD_TIMEOUT ||
      errorCode === google.ima.AdError.ErrorCode.VAST_LOAD_TIMEOUT ||
      errorCode === google.ima.AdError.ErrorCode.ADS_REQUEST_NETWORK_ERROR ||
      errorCode === google.ima.AdError.ErrorCode.FAILED_TO_REQUEST_ADS
  ) {
    if (retryCount < maxRetries) {
      retryCount++;
      // console.log(`🔁 Retrying ad request (${retryCount}/${maxRetries})`);
      setTimeout(() => {
        adsLoader.requestAds(adsRequest);
      }, 3000);
    } else {
      console.error("❌ Max retries reached. Skipping ads.");
      fallbackToVideoPlayback();
    }
  } else {
    fallbackToVideoPlayback();
  }
}




// function fallbackToVideoPlayback() {
//   console.warn("⚠️ Falling back to video playback...");
//   if (videoElement) {
//     videoElement.play().catch((err) => console.error("🎥 Video Play Error:", err));
//   }
// }

const fallbackToVideoPlayback = async () => {
  try {
    if (videoElement) {
      // console.warn("Falling back to video playback...");
      await videoElement.play();
    }
  } catch (playError) {
    console.error("Error resuming video playback: ", playError);
  }
};






function createAdTimer() {
  if (adTimer) {
    adTimer.remove();
  }

  adTimer = document.createElement("div");
  adTimer.id = "ad-timer";
  adTimer.style.position = "absolute";
  adTimer.style.bottom = "10px";
  adTimer.style.left = "10px";
  adTimer.style.backgroundColor = "rgba(0, 0, 0, 0.7)";
  adTimer.style.color = "white";
  adTimer.style.padding = "5px 10px";
  adTimer.style.borderRadius = "5px";
  adTimer.style.fontSize = "14px";
  adTimer.style.fontWeight = "bold";
  adTimer.style.zIndex = "10000";

  if (adContainer) {
    // console.log("adContainersnsnsn", adContainer)
    adContainer.appendChild(adTimer);
  }
}
// Modify updateAdTimer function to use adDurationFromVAST
let adRemainingTime = null; // Track remaining time for countdown

function updateAdTimer(currentAdIndex, totalAds) {
  if (!adVideoElement) {
    // console.warn("⚠️ No Ad Video Element Detected! Attempting to find...");
    adVideoElement = adContainer?.querySelector("video");
  }

  if (!adTimer || !adVideoElement) {
    // console.warn("⚠️ Ad timer or ad video element is still missing");
    return;
  }

  // Reset remaining time when a new ad starts
  adRemainingTime = adDurationFromVAST || adVideoElement.duration;

  if (!adRemainingTime || isNaN(adRemainingTime) || adRemainingTime === Infinity) {
    adTimer.innerText = totalAds > 1 ? `Ad ${currentAdIndex} of ${totalAds}` : "Ad Loading...";
    return;
  }

  // Clear existing interval before starting a new countdown
  clearInterval(adTimerInterval);

  // Start countdown timer
  adTimerInterval = setInterval(() => {
    if (adRemainingTime <= 0) {
      clearInterval(adTimerInterval);
      adTimer.innerText = `Ad Completed`;
      return;
    }

    adRemainingTime--;

    const minutes = Math.floor(adRemainingTime / 60);
    const seconds = adRemainingTime % 60;

    const adText = totalAds > 1 ? `Ad ${currentAdIndex} of ${totalAds} ` : "";
    const timeText = `Ends in ${minutes}:${seconds.toString().padStart(2, "0")}`;
    
    adTimer.innerText = `${adText}${timeText}`;
  }, 1000);
}





function setupAdClickListener(adEvent) {
  if (!adContainer) {
    console.error("🚨 Ad container is missing!");
    return;
  }

  // console.log("🔗 Enabling ad click listener for this ad...");

  // Prevent default click behavior on the ad container
  adContainer.addEventListener("click", (event) => {
    event.preventDefault();
    event.stopPropagation();
    // console.log("🖱️ Click detected on ad.");

    let adData = adEvent.getAd(); // Get the ad object
    // console.log("adDatasjsj", adData)
    if (!adData) {
      // console.warn("⚠️ No ad data found.");
      return;
    }

    let clickThroughUrl = adData.data?.clickThroughUrl;
    if (clickThroughUrl) {
      // console.log("🔗 Redirecting to:", clickThroughUrl);
      window.open(clickThroughUrl, "_blank");
    }
  }, true); // Using capture phase

  let prevAdWidth, prevAdHeight;


  
  
  
  
  

  // Enhanced video element click prevention
  if (adVideoElement) {
    
    // Prevent all click-related events
    ['click', 'mousedown', 'mouseup'].forEach(eventType => {
      adVideoElement.addEventListener(eventType, (event) => {
        // console.log(`🚫 Preventing ${eventType} event on ad video`);
        event.preventDefault();
        event.stopPropagation();
      }, true); // Using capture phase
    });

    // console.log("isadplayyyy", isAdPlaying)
    let adIsPaused = false; // ✅ Track ad pause state

    adVideoElement.addEventListener('pause', (event) => {
      // console.log("⏸️ Preventing ad pause");
    
      event.preventDefault(); // ✅ Stop default pause behavior
      adIsPaused = true; // ✅ Mark ad as paused
    
      if (adsManager) {
        // console.log("▶️ Resuming ad via adsManager...");
        adsManager.resume(); // ✅ Resume the ad using Google IMA SDK
      } else {
        adVideoElement.play().catch(err => console.error("🚨 Error resuming ad:", err));
      }
    }, true);
    
    // ✅ Ensure click event is always attached
    adVideoElement.addEventListener('click', (event) => {
      if (adIsPaused) {
        // console.log("▶️ Ad was paused, resuming...");
        event.preventDefault();
    
        if (adsManager) {
          adsManager.resume(); // ✅ Resume ad playback
        } else {
          adVideoElement.play().catch(err => console.error("🚨 Error resuming ad:", err));
        }
    
        adIsPaused = false; // ✅ Reset pause state
      }
    }, true);
    
    
    
    
  }
  

  // Optional: Prevent right-click context menu
  adContainer.addEventListener('contextmenu', (event) => {
    event.preventDefault();
  });
}



function removeAdClickListener() {
  if (!adContainer) return;
  // console.log("🔄 Removing ad click listener...");
  adContainer.removeEventListener("click", setupAdClickListener);
}

function logAdVideoSize() {
  if (adVideoElement) {
    // console.log("📏 Ad Video Size:", {
    //   width: adVideoElement.clientWidth,
    //   height: adVideoElement.clientHeight,
    //   boundingRect: adVideoElement.getBoundingClientRect() // Logs exact position on screen
    // });
  } else {
    // console.warn("⚠️ Ad video element not found!");
  }
}

function disableMouseEvents(event) {
  if (document.fullscreenElement) {
      event.stopPropagation();
      event.preventDefault();
  }
}
async function onAdEvent(adEvent) {
  // console.log("🎬 Ad Event Triggered:", adEvent.type);

  var ad = adEvent.getAd();
  if (!ad) {
    // console.warn("⚠️ Ad object is NULL. Event:", adEvent.type);
    return;
  }

  switch (adEvent.type) {
    case google.ima.AdEvent.Type.LOADED:
      // console.log("✅ Ad Loaded:", ad);
      if (!ad.isLinear()) {
        try {
          await videoElement.play();
        } catch (error) {
          console.error("❌ Error playing video:", error);
        }
      }
      break;

    case google.ima.AdEvent.Type.STARTED:
      // console.log("🎥 Ad Started");

      // Ensure the ad container exists
      if (!adContainer) {
        console.error("🚨 Ad container is missing!");
        return;
      }

      if (adContainer) {
        adVideoElement =
          adContainer.querySelector('video[style*="display: block"]') ||
          adContainer.querySelector("video[src]");
        // createPlayPauseButton();
        // createMuteButton();
        // startAdTimer();
        retryCount = 0;
      }

      
      // const plyrcontrols = document.querySelector(".plyr__controls");

      // if (document.fullscreenElement && plyrcontrols) {
      //   if (isAdPlaying) {
      //     // console.log("⏳ Ad is playing, hiding controls...");
      //     plyrcontrols.style.visibility = "hidden";
      //   }
      // }
      
      // ✅ Listen for ad completion event to restore controls
      adsManager.addEventListener(google.ima.AdEvent.Type.ALL_ADS_COMPLETED, () => {
        // console.log("🏁 Ad Completed, restoring controls...");
      
        // if (plyrcontrols) {
        //   plyrcontrols.style.visibility = "visible";
        //   plyrcontrols.style.opacity = "1";
        //   plyrcontrols.style.display = "flex"; // ✅ Ensures it's displayed if hidden
        //   // console.log("✅ Plyr controls restored.");
        // } else {
        //   console.warn("⚠️ Could not find Plyr controls, retrying...");
        //   setTimeout(() => {
        //     const retryControls = document.querySelector(".plyr__controls");
        //     if (retryControls) {
        //       retryControls.style.visibility = "visible";
        //       retryControls.style.opacity = "1";
        //       retryControls.style.display = "flex";
        //       // console.log("✅ Successfully restored Plyr controls after retry.");
        //     }
        //   }, 500); // ✅ Small delay to ensure DOM is updated
        // }
      });
      

      // Find the ad video element inside the container
      adVideoElement = adContainer.querySelector("video");
 // Hide progress bar during ad playback

      if (!adVideoElement) {
        // console.warn("⚠️ No ad video element found, trying again...");
        setTimeout(() => {
          adVideoElement = adContainer.querySelector("video");
          if (!adVideoElement) {
            console.error("❌ Still no ad video element detected!");
            return;
          }
          logAdVideoSize();  // Log ad size when found
        }, 500); // Small delay to ensure the video loads
      } else {
        logAdVideoSize();
        // console.log("✅ Ad video element found:", adVideoElement);
      }

      let currentAdIndex;
  let totalAds;

  if (ad.getAdPodInfo) {
    const adPodInfo = ad.getAdPodInfo();
    // console.log("adPodInfojasj", adPodInfo)
    currentAdIndex = adPodInfo?.data?.adPosition;
    totalAds = adPodInfo?.data?.totalAds;

    // console.log(`📊 Now Playing Ad ${currentAdIndex} of ${totalAds}`);
  }

      // Create and start Ad Timer
      if (window.innerWidth >= 480) { // Assuming 1024px is the breakpoint for large screens
        createAdTimer();
      }
      

      if (adTimerInterval) {
        clearInterval(adTimerInterval);
      }
      adTimerInterval = setInterval(() => updateAdTimer(currentAdIndex, totalAds), 1000);

      // ✅ Disable shortcut keys during ALL ads (preroll, midroll, postroll)
      // console.log("🚫 Disabling shortcut keys...");
      document.addEventListener("keydown", disableKeyboardShortcuts, true);


      // console.log("askskksks", ad); // ✅ This returns `true` or `false`
      // ✅ Enable Skip Button if Skippable

 
  // ✅ Prevent pausing behavior for both preroll and midroll
  setupAdClickListener(adEvent);


      break;

    case google.ima.AdEvent.Type.COMPLETE:
    case google.ima.AdEvent.Type.SKIPPED:
    case google.ima.AdEvent.Type.ALL_ADS_COMPLETED:
      // console.log("🏁 Ad Completed, Stopping Timer...");

      if (adTimerInterval) {
        clearInterval(adTimerInterval);
        adTimerInterval = null;
      }

      if (adTimer) {
        adTimer.remove();
        adTimer = null;
      }

      retryCount = 0;
      if (videoElement.muted && isIOSDevice) {
        videoElement.muted = false;
      }

       // Show progress bar again
  // if (plyrcontrols) {
  //   plyrcontrols.style.display = "block";
  //   console.log("🟢 Progress bar restored after ad playback.");
  // }

      // ✅ Remove Play/Pause Button
      // removePlayPauseButton();
      // removeMuteButton();

      removeAdClickListener()
      // ✅ Re-enable shortcut keys after ad ends
      // console.log("✅ Re-enabling shortcut keys...");
      document.removeEventListener("keydown", disableKeyboardShortcuts, true);

    //   document.addEventListener("fullscreenchange", () => {
    //     if (document.fullscreenElement) {
    //         document.addEventListener("mousedown", disableMouseEvents, true);
    //         document.addEventListener("mouseup", disableMouseEvents, true);
    //         document.addEventListener("click", disableMouseEvents, true);
    //         document.addEventListener("dblclick", disableMouseEvents, true);
    //     } else {
    //         document.removeEventListener("mousedown", disableMouseEvents, true);
    //         document.removeEventListener("mouseup", disableMouseEvents, true);
    //         document.removeEventListener("click", disableMouseEvents, true);
    //         document.removeEventListener("dblclick", disableMouseEvents, true);
    //     }
    // });

      break;

    default:
      // console.log(`🔹 Ad Event: ${adEvent.type}`);
      break;
  }
}



function disableKeyboardShortcuts(event) {
  const blockedKeys = [" ", "ArrowLeft", "ArrowRight", "m", "MediaPlayPause"];

  if (blockedKeys.includes(event.key)) {
    event.preventDefault();
    event.stopPropagation(); // ✅ Prevent event from affecting background video
    // console.log(`🚫 Blocked key: ${event.key} while ad is playing`);
  }
}


export const cancelAds = () => {
  // console.log("❌ Canceling Ads...");
  if (adsManager) {
    adsManager.destroy();
    adsManager = null;
  }
  adInitialized = false;
  adsLoaded = false;
  isAdPlaying = false;
  clearAllEventListeners();


  // ✅ Remove shortcut key restrictions when ads are canceled
  // console.log("✅ Re-enabling shortcut keys...");
  document.removeEventListener("keydown", disableKeyboardShortcuts, true);

  

  videoElement.play().catch((err) => console.error("🚨 Error resuming main video:", err));
};

async function adContainerClick(event) {
  if (videoElement) {
    if (videoElement.paused) {
      try {
        await videoElement.play();
      } catch (error) {
        console.error("Error resuming video playback:", error);
      }
    } else {
      try {
        await videoElement.pause();
      } catch (error) {
        console.error("Error pausing video:", error);
      }
    }
  }
}


function handleDoubleClick(event) {
  if (isAdPlaying) {
    event.preventDefault();
    event.stopPropagation();
  }
}



function clearAllEventListeners() {
  if (adContainer) {
    adContainer.removeEventListener("click", adContainerClick);
    adContainer.removeEventListener("dblclick", handleDoubleClick);
  }
  

  if (videoElement) {
    videoElement.removeEventListener("ended", onContentComplete);
  }
  window.removeEventListener("resize", onWindowResize);
  if (adsManager) {
    adsManager.removeEventListener(
      google.ima.AdErrorEvent.Type.AD_ERROR,
      onAdError
    );
    const events = [
      google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
      google.ima.AdEvent.Type.CLICK,
      google.ima.AdEvent.Type.COMPLETE,
      google.ima.AdEvent.Type.FIRST_QUARTILE,
      google.ima.AdEvent.Type.LOADED,
      google.ima.AdEvent.Type.MIDPOINT,
      google.ima.AdEvent.Type.PAUSED,
      google.ima.AdEvent.Type.RESUMED,
      google.ima.AdEvent.Type.STARTED,
      google.ima.AdEvent.Type.THIRD_QUARTILE,
    ];
    for (const eventType of events) {
      adsManager.removeEventListener(eventType, onAdEvent);
    }
  }
}