import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../Componets/Header/Header";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import Footer from "../../Componets/Footer/Footer";
import { useParams } from "react-router";
import { BASE_URL, acceptedMediaTypes, access_token, auth_token } from "../../services/config";
import axios from "axios";
import Slider from "react-slick";
import { horizontalmodel, verticalmodel } from "../../layout/gridmodel";
import { useNavigate, Link } from "react-router-dom";
import { FaAngleDoubleRight, FaPlay } from "react-icons/fa";
import Loader from "../../Componets/loader.js";
import {
  SkeletonLoad,
  SkeletonLoadBox,
  SkeletonLoadBoxShows,
  SkeletonLoadSlideWinOriginals,
  SkeletonLoadSlider,SkeletonLoadBoxMOre,
} from "../skeletonLoad/skeletonthumbnail.jsx";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {
  useComponentUtils,
} from "../../layout/utilscomponent";
import { useDispatch, useSelector } from "react-redux";
import { setMainTraynamedata, setTraynamedata } from "../../Redux/Slice/navlinkcallSlice.js";
import { setThroughSeach } from "../../Redux/Slice/throughSearchSlice.js";
const MoreDetails = () => {
  const verticaldefultimage = "/images/horizontaldefault.jpg";
  const horizontaldefultimage = "/images/verticaldefault.jpg";
  const etv_exclusive_banner_image = "/images/etv-exclusive_banner.png";
  const tv_shows_banner_image = "/images/tv_shows_banner.png";
  const top10_image = "/images/top10default.jpg";
  const twoGridDefault = "/images/twogridimage.jpg";
  const singleGridDefault = "/images/singlegriddefault.jpg";
  const nonvegicon = "/images/nonveg.png";
  const vegicon = "/images/veg.png";
  const effectRan = useRef(false);
  const [currentImageClick, setCurrentImageClick] = useState(null);

  const [movieGenresList, setMovieGenresList] = useState([]);
  const regionsData = useSelector((state) => state.region.regionsData);
  const { sessionId } = useSelector((state) => state.auth);
  let sessionID = localStorage.getItem("session_id")
    ? localStorage.getItem("session_id")
    : sessionId;
  const [movieGenresData, setMovieGenresData] = useState({
    data: [],
    total_items_count: 0,
    title: "",
  });
  const dispatch =  useDispatch()
  const [healthGenresData, setHealthGenresData] = useState({
    data: [],
    total_items_count: 0,
    title: "",
  });
  const [moreData, setMoreData] = useState({
    data: [],
    total_items_count: 0,
    title: "",
    layout_type: "",
    filter_title: "",
  });
  // console.log("moreDatass",moreData);

  const [page, setPage] = useState(0);
  const [fromItem, setFromItem] = useState(0);
  const [pageSize, setPageSize] = useState(24);
  const [isMoreAdded, setIsMoreAdded] = useState(true);
  const [catalogListData, setCatalogListData] = useState({
    data: [],
    total_items_count: 0,
    title: "",
  });
  const [genresCatalogListData, setGenresCatalogListData] = useState([]);
  const [healthgenresCatalogListData, setHealthGenresCatalogListData] =
    useState([]);
  const [avilFilters, setAvilFilters] = useState([]);
  const {
    pageName,
    friendly_id,
    moreseo_url,
    category,
    main,
    channelID,
    pageName2,
    categoryItem,
    
  } = useParams();
  const seo_url = moreseo_url;
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedvalue, setselectedValue] = useState("");
  const [selectedkey, setselectedKey] = useState("");
  const [initialApiCalled, setInitialApiCalled] = useState(false);
  const [loadingbusy, setLoadingbusy] = useState(false);
  const [moredataLoading, setMoredataLoading] = useState(false);
  const [isrecordsfound, setIsrecordsfound] = useState(true);
  const [isDataEmpty, setIsDataEmpty] = useState(false);
// Define a state variable to hold the total number of items
const [totalItemsCount, setTotalItemsCount] = useState(0);
// Define a state variable to hold the loading state
  const { renderCategoryBlockWithLayoutType } = useComponentUtils(
    movieGenresList,

    true,
    {},
    {},
    false,
    {},
    {},
    {}
  );
  //alert(JSON.stringify(selectedkey));
  const [lastItemIndex, setLastItemIndex] = useState(0);
  const SublistTitle = localStorage.getItem("SublistTitle");
  // console.log(channelID, "channelID")
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);

  useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth < 640);
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {

    if (isMobile) {
      window.scrollTo(0, 0);
    }
  }, [isMobile]);
  
  const friendlyIdToUrlMap = {
    [pageName ? pageName : pageName2]: pageName
      ? `catalog_lists/${pageName}.gzip`
      : `catalog_lists/${pageName2}.gzip`,
  };
  // console.log("morepage");
  const getMoreDetailsUrl = () => {
    // const baseUrl = `${BASE_URL}catalog_lists/${pageName}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=0&page=0&page_size=24&start_count=0`;
    var parentId = localStorage.getItem("more_parent_id");
    var localstoragecategory = localStorage.getItem("more_category");
    if (friendlyIdToUrlMap[pageName] && channelID == undefined) {
      // console.log("if");
      let more_parent_id = 0;
      let more_category = "";
      if (parentId !== undefined) {
        more_parent_id = parentId;
      }
      if (localstoragecategory !== undefined) {
        more_category = localstoragecategory;
      }

      if (more_parent_id != 0 && more_category != "") {
        return `${BASE_URL}${friendlyIdToUrlMap[pageName]}?category=${more_category}&parent_id=${more_parent_id}&item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=${fromItem}&page=${page}&page_size=24&start_count=0`;
      } else {
        return `${BASE_URL}${friendlyIdToUrlMap[pageName]}?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=${fromItem}&page=${page}&page_size=24&start_count=0`;
      }
    } else if (channelID != undefined && pageName != "") {
      // console.log("else if");
    
      return `${BASE_URL}catalog_lists/${pageName}?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&${selectedkey}=${channelID}&from=${fromItem}&page=${page}&page_size=24&start_count=0`;
    } else {
      return;
    }
  };

  const getmoredetailscontent = getMoreDetailsUrl();
//alert("hello");
  // console.log("getmoredetailscontent", getmoredetailscontent);
  const loginStatus = localStorage.getItem("login_status")
  const guestuserid = `Guest ${Math.floor(2e4 * Math.random())}`;
  const getuserid = localStorage.getItem("user_id")
  const { navItemCalled } = useSelector((state) => state.navmenu_called);

// console.log("navItemCalled", navItemCalled)
function getPageName() {
  const path = window.location.pathname;
  const parts = path.split('/'); // Split path by '/'
  return parts[1] ?? "home"; // Return the second part after localhost/
}
const param1Value = getPageName();
const sourcetab = localStorage.getItem("selectedMenuItem");

const itemSelected = (index,listItem, moredata) => {
  dispatch(setThroughSeach({ throughSeach: false }));
  // console.log("moredatassss", moredata.title?.toLowerCase())
  // console.log("listItemssss", listItem)
  // console.log("moredatasss", moredata)
  localStorage.setItem("traynamedata", moredata.title?.toLowerCase())
  dispatch(setTraynamedata({ traynamedata:  listItem}));
  dispatch(setMainTraynamedata({ maintraynamedata:  moredata}));
  if (window && window.sdk && window.sdk.trackEvent) {
    window.sdk.trackEvent("artwork_tapped", {
      source:sourcetab?.toLowerCase(),
      country: regionsData?.country_name,
      event_time: new Date().toISOString().slice(0, 19),
      video_name:listItem.title,
      tray_name: (moredata.display_title?.toLowerCase() ? moredata.display_title?.toLowerCase() : moredata.title?.toLowerCase()) || "NA",
      platform: "web",
      city: regionsData?.city_name,
      event_id: 21,
      u_id:
      loginStatus === "true"
      ? getuserid
      : guestuserid,
      content_type: (listItem?.media_type ? listItem?.media_type : listItem?.last_episode?.media_type) || 'NA',
      position_within_tray: index + 1,
      artwork_type: (listItem?.media_type ? listItem?.media_type : listItem?.last_episode?.media_type) || 'NA',
 });

  } else {
    console.error('Analytics SDK is not initialized yet.');
    // Handle initialization state or queue events for later
  }

 

};
  const fetchMoreDetailsData = async () => {
    //  alert(selectedvalue);
       // const baseUrl = `${BASE_URL}catalog_lists/${pageName}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=0&page=0&page_size=24&start_count=0`;
       var parentId = localStorage.getItem("more_parent_id");
       var localstoragecategory = localStorage.getItem("more_category"); 
       const dropdownChannelKey = selectedkey || localStorage.getItem("dropdownChannelKey") || "genres";
       //var dropdownChannelKey =  dropdownChannelKey;
       // if(selectedkey == ""){
       //   selectedkey = dropdownChannelKey;
       // }
    //   alert(dropdownChannelKey);
      // setselectedKey(dropdownChannelKey);
       var dropdownChannelValue = channelID;
 
       var url= "";
      //  console.log("dropdownChannelKey",dropdownChannelKey);
      //  console.log("dropdownChannelValue",dropdownChannelValue);
 
       // if (friendlyIdToUrlMap[pageName] && channelID == undefined) {
       //   console.log("if");
         let more_parent_id = 0;
         let more_category = "";
         if (parentId !== undefined) {
           more_parent_id = parentId;
         }
         if (localstoragecategory !== undefined) {
           more_category = localstoragecategory;
         }
   
       //   if (more_parent_id != 0 && more_category != "") {
       //     url= `${BASE_URL}${friendlyIdToUrlMap[pageName]}?category=${more_category}&parent_id=${more_parent_id}&item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=${fromItem}&page=${page}&page_size=24&start_count=0`;
       //   } else {
       //     url= `${BASE_URL}${friendlyIdToUrlMap[pageName]}?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=${fromItem}&page=${page}&page_size=24&start_count=0`;
       //   }
       // } else 
       // if (channelID != undefined && pageName != "" && selectedkey!="") {
       //   = `${BASE_URL}catalog_lists/${pageName}?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&${selectedkey}=${channelID}&from=${fromItem}&page=${page}&page_size=24&start_count=0`;
       // } else {
 
       // }
 
       const parameters = {
         from: fromItem,
         page_size: pageSize,
         page: page,
         start_count: 0
       };
       if (dropdownChannelValue && dropdownChannelKey) {
         parameters[dropdownChannelKey] = dropdownChannelValue;
       }
       if (more_parent_id != 0 && more_category != "") {
         parameters.parent_id  = more_parent_id;
       }
       if (more_parent_id != 0 && more_category != "") {
         parameters.parent_id  = more_parent_id;
       }

       // if (sessionID) {
       //   parameters.session_id = sessionID;
       // }
       // if (pageName === "movies-popular-genres") {
       //   return `${BASE_URL}catalog_lists/${pageName}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=${fromItem}&page=${page}&page_size=24&start_count=0`;
       // }
       // if (pageName === "movies-heros-special") {
       //   return `${BASE_URL}catalog_lists/${pageName}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=${fromItem}&page=${page}&page_size=24&start_count=0`;
       // }
       // if (pageName === "movies-directors-special") {
       //   return `${BASE_URL}catalog_lists/${pageName}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=${fromItem}&page=${page}&page_size=24&start_count=0`;
       // }else{
         
       // }
      if(categoryItem!== undefined){
        // console.log("firstttt")
       url= `${BASE_URL}catalog_lists/${categoryItem}?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
 
      }
      else if(navItemCalled){
        // console.log("seconddddd")
       url= `${BASE_URL}catalog_lists/${pageName}?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
 
      }else{
        // console.log("thirdddddd", pageName)
        url= `${BASE_URL}catalog_lists/${pageName}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;

      }

 
      //alert(url);
     //setLoadingbusy(false);
     setLoading(true);
     try {
       const response = await axios.get(url, { params: parameters });
       if (response.status === 200) {
         setLoading(false);
          // console.log("moreresp",response);
         setLoadingbusy(true);
         const newItems = response?.data?.data?.catalog_list_items || response?.data?.data?.items || [];
         const totalItemsCount = response?.data?.data?.total_items_count || 0;
         const uniqueTitles = new Set(moreData.data.map((item) => item.title));
       //  console.log(uniqueTitles, "uniqueTitles"); // Logging unique titles for debugging
       //  console.log("New items before filtering:", newItems);
 
         // Filter out new items with titles that already exist in the current data
         const filteredNewItems = newItems.filter(
           (newItem) => !uniqueTitles.has(newItem.title)
         );
         const updatedResults = [...moreData?.data,...newItems ];
 
         setMoreData((prevData) => ({
           ...prevData,
           data: updatedResults, // Append only filtered new data to existing data
           total_items_count: totalItemsCount,
           title: response?.data?.data?.display_title?( response?.data?.data?.display_title): response?.data?.data?.title,
        layout_type: (response?.data?.data?.web_layout_type ?? response?.data?.data?.layout_type),
           filter_title: response?.data?.data?.filter_title,
         }));
         const newPage = page + 1;
         setPage(newPage);
         setFromItem(updatedResults.length);
 
         // if (moreData?.data?.length > 0) {
         //   setLoading(true);
         // }
         setAvilFilters(response?.data?.data?.available_filters);
 
         setCatalogListData((prevData) => ({
           ...prevData,
           data: newItems,
 
           total_items_count: totalItemsCount,
           title: response?.data?.data?.display_title,
         }));
         setTotalItemsCount(totalItemsCount);
         setLastItemIndex((prev) => prev + newItems.length);
       }else{
         setLoadingbusy(false);
       }
     } catch (error) {
       setLoading(false);
       setLoadingbusy(false);
       console.error("Error fetching details:", error);
       if (error.message === "Request failed with status code 422") {
         console.log(error.message);
       } else if (error?.response?.status === 422) {
         console.log(error?.response?.data?.error?.message);
       }
     }
   };


   const fetchMoreData = async () => {
    //  alert(selectedvalue);
   
       // const baseUrl = `${BASE_URL}catalog_lists/${pageName}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=0&page=0&page_size=24&start_count=0`;
       var parentId = localStorage.getItem("more_parent_id");
       var localstoragecategory = localStorage.getItem("more_category"); 
       const dropdownChannelKey = selectedkey || localStorage.getItem("dropdownChannelKey") || "genres";
       //var dropdownChannelKey =  dropdownChannelKey;
       // if(selectedkey == ""){
       //   selectedkey = dropdownChannelKey;
       // }
    //   alert(dropdownChannelKey);
      // setselectedKey(dropdownChannelKey);
       var dropdownChannelValue = channelID;
 
       var url= "";
      //  console.log("dropdownChannelKey",dropdownChannelKey);
      //  console.log("dropdownChannelValue",dropdownChannelValue);
 
       // if (friendlyIdToUrlMap[pageName] && channelID == undefined) {
       //   console.log("if");
         let more_parent_id = 0;
         let more_category = "";
         if (parentId !== undefined) {
           more_parent_id = parentId;
         }
         if (localstoragecategory !== undefined) {
           more_category = localstoragecategory;
         }
   
       //   if (more_parent_id != 0 && more_category != "") {
       //     url= `${BASE_URL}${friendlyIdToUrlMap[pageName]}?category=${more_category}&parent_id=${more_parent_id}&item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=${fromItem}&page=${page}&page_size=24&start_count=0`;
       //   } else {
       //     url= `${BASE_URL}${friendlyIdToUrlMap[pageName]}?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=${fromItem}&page=${page}&page_size=24&start_count=0`;
       //   }
       // } else 
       // if (channelID != undefined && pageName != "" && selectedkey!="") {
       //   = `${BASE_URL}catalog_lists/${pageName}?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&${selectedkey}=${channelID}&from=${fromItem}&page=${page}&page_size=24&start_count=0`;
       // } else {
 
       // }
 
       const parameters = {
         from: fromItem,
         page_size: 24,
         page: page,
         start_count: 0
       };
       if (dropdownChannelValue && dropdownChannelKey) {
         parameters[dropdownChannelKey] = dropdownChannelValue;
       }
       if (more_parent_id != 0 && more_category != "") {
         parameters.parent_id  = more_parent_id;
       }
       if (more_parent_id != 0 && more_category != "") {
         parameters.parent_id  = more_parent_id;
       }
       
       // if (sessionID) {
       //   parameters.session_id = sessionID;
       // }
       // if (pageName === "movies-popular-genres") {
       //   return `${BASE_URL}catalog_lists/${pageName}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=${fromItem}&page=${page}&page_size=24&start_count=0`;
       // }
       // if (pageName === "movies-heros-special") {
       //   return `${BASE_URL}catalog_lists/${pageName}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=${fromItem}&page=${page}&page_size=24&start_count=0`;
       // }
       // if (pageName === "movies-directors-special") {
       //   return `${BASE_URL}catalog_lists/${pageName}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=${fromItem}&page=${page}&page_size=24&start_count=0`;
       // }else{
         
       // }
      if(categoryItem!== undefined){
       url= `${BASE_URL}catalog_lists/${categoryItem}?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
 
      }else{
       url= `${BASE_URL}catalog_lists/${pageName}?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
 
      }
 
      //alert(url);
     //setLoadingbusy(false);
     try {
      if (moredataLoading) return; 
    setMoredataLoading(true);  
       const response = await axios.get(url, { params: parameters });
       if (response.status === 200) {
         setLoading(false);
          // console.log("moreresp",response);
         setLoadingbusy(true);
         const newItems = response?.data?.data?.catalog_list_items || response?.data?.data?.items || [];
         const totalItemsCount = response?.data?.data?.total_items_count || 0;
         const uniqueTitles = new Set(moreData.data.map((item) => item.title));
       //  console.log(uniqueTitles, "uniqueTitles"); // Logging unique titles for debugging
       //  console.log("New items before filtering:", newItems);
 
         // Filter out new items with titles that already exist in the current data
         const filteredNewItems = newItems.filter(
           (newItem) => !uniqueTitles.has(newItem.title)
         );
         const updatedResults = [...moreData?.data,...newItems ];
 
         setMoreData((prevData) => ({
           ...prevData,
           data: updatedResults, // Append only filtered new data to existing data
           total_items_count: totalItemsCount,
           title: response?.data?.data?.display_title?( response?.data?.data?.display_title): response?.data?.data?.title,
           layout_type: (response?.data?.data?.web_layout_type ?? response?.data?.data?.layout_type),
           filter_title: response?.data?.data?.filter_title,
         }));
         const newPage = page + 1;
         setPage(newPage);
         setFromItem(updatedResults.length); 
 
         // if (moreData?.data?.length > 0) {
         //   setLoading(true);
         // }
         setAvilFilters(response?.data?.data?.available_filters);
 
         setCatalogListData((prevData) => ({
           ...prevData,
           data: newItems,
 
           total_items_count: totalItemsCount,
           title: response?.data?.data?.display_title,
         }));
         setTotalItemsCount(totalItemsCount);
         setLastItemIndex((prev) => prev + newItems.length);
         setMoredataLoading(false) 
         if(response?.data?.data?.catalog_list_items?.length === 0){
           setIsrecordsfound(false);
         }else{
         setIsrecordsfound(true);
         }
        //  console.log('moresrsssskkk', response?.data?.data)
       }else{
        setIsrecordsfound(true);
         setLoadingbusy(false);
       }
     } catch (error) {
      setMoredataLoading(false)
       setLoadingbusy(false);
       console.error("Error fetching details:", error);
       if (error.message === "Request failed with status code 422") {
         console.log(error.message);
       } else if (error?.response?.status === 422) {
         console.log(error?.response?.data?.error?.message);
       }
     }
   };

  const handleMore = (link, data, identifyunic) => {

    fetchMoreData();
  navigate(link);
  window.location.reload();

  };



  const generateVerticalModel = (item) => {
    let verticalmodel = {
      dots: false,
      infinite: false,
      speed: 500,
      lazyload: "anticipated",
      autoplay: false,
      delay: 3000,
      slidestoshow: 9,
      slidestoscroll: 4,
      nextarrow: <SampleNextArrow />,
      prevarrow: <SamplePrevArrow />,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidestoshow: 4,
            slidestoscroll: 1,
            infinite: false,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidestoshow: getSlidesToShow(item),
            slidestoscroll: 1,
            infinite: false,
            dots: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidestoshow: 3,
            slidestoscroll: 1,
            infinite: false,
            dots: false,
          },
        },
      ],
    };
    return verticalmodel;
  };


  const generateHorizontalModel = (item) => {
    let horizontalmodel = {
      dots: false,
      infinite: false,
      speed: 500,
       lazyload: "anticipated",
      // swipe: false,
      autoplay: false,
      centermode: false,
      delay: 3000,
      slidestoshow: 6.5,
      slidestoscroll: 3,
      initialSlide: 0,
      nextarrow: <SampleNextArrow  />,
      prevarrow: <SamplePrevArrow/>,
      //   nextArrow: <SampleNextArrow wrapperClassName="arrow-wrapper" />,
      // prevarrow: <SamplePrevArrow wrapperClassName="arrow-wrapper" />,
      // prevarrow: null,
      
    
  
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidestoshow: 3.5,
            slidestoscroll: 3,
            dots: false,
            infinite: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidestoshow: 2,
            slidestoscroll: 2,
            initialSlide: 2,
            dots: false,
            infinite: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidestoshow: getSlidesToShow(item),
            slidestoscroll: 2,
            initialSlide: 2,
            dots: false,
            infinite: false,
            
          },
        },
      ],
    };
    return horizontalmodel;
  };

  

  function getSlidesToShow(data) {
    return data && data.total_items_count >= 2 ? 3 : 1;
  }


    const SamplePrevArrow = (props) => {
      const { className, onClick } = props
      return (
          <div 
              className={className}
              // className={`arrow ${className}`}
              onClick={onClick}
              style={{ cursor: "pointer" }} 
          >
             <GoChevronLeft />
              {/* <FaChevronLeft className='arrows' style={{ fontWeight: 100, background: 'transparent'}} /> */}
          </div>
      );
  }
  
  const SampleNextArrow = (props) => {
    const { className, onClick } = props
    return (
        <div 
            className={className}
            // className={`arrow ${className}`}
            onClick={onClick}
            style={{ cursor: "pointer" }} 
        >
          <GoChevronRight  />
  
            {/* <FaChevronRight className='arrows' style={{ fontWeight: 100, background: 'transparent' }} /> */}
        </div>
    );
  }


  const generateBigModel = (item) => {
    let bigmodel = {
      dots: false,
      infinite: false,
      speed: 500,
       lazyload: "anticipated",
      autoplay: false,
      delay: 3000,
      slidestoshow: 6.5,
      slidestoscroll: 4,
      nextarrow: <SampleNextArrow  />,
      prevarrow: <SamplePrevArrow/>,
  
  
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidestoshow: 3.8,
            slidestoscroll: 3,
            dots: false,
            infinite: false,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidestoshow: 2.8,
            slidestoscroll: 2,
            dots: false,
            infinite: false,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidestoshow: getSlidesToShow(item),
            slidestoscroll: 2,
            dots: false,
            infinite: false,
          }
        }
      ]
  
  
    };
    return bigmodel;
  };


  const getSingelgridModel = (item) => {
    var singelgrid = {
      dots: false,
      infinite: false,
      speed: 500,
       lazyload: "anticipated",
      autoplay: false,
      delay: 3000,
      slidestoshow: 1,
      slidestoscroll: 1,
      // swipe: false,
      nextarrow: <SampleNextArrow  />,
      prevarrow: <SamplePrevArrow />,
  
  
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidestoshow: 3.8,
            slidestoscroll: 3,
            dots: false,
            infinite: false,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidestoshow: 2.8,
            slidestoscroll: 2,
            dots: false,
            infinite: false,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidestoshow: getSlidesToShow(item),
            slidestoscroll: 2,
            dots: false,
            infinite: false,
          }
        }
      ]
  
  
    };
    return singelgrid;
  };

   

  var twogrid = {
    dots: false,
    // asNavFor: '.twogrid',
    infinite: false,
    speed: 500,
     lazyload: "anticipated",
    autoplay: false,
    delay: 3000,
    slidestoshow: 2,
    slidestoscroll: 1,
    nextarrow: <SampleNextArrow  />,
    prevarrow: <SamplePrevArrow />,


    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidestoshow: 2,
          slidestoscroll: 3,
          dots: false,
          infinite: false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidestoshow: 2,
          slidestoscroll: 2,
          dots: false,
          infinite: false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidestoshow: 1.3,
          slidestoscroll: 2,
          dots: false,
          infinite: false,
        }
      }
    ]


  };


  const handleMoreDataScroll = () => {
    const scrollTop = (document?.documentElement?.scrollTop || document?.body?.scrollTop || 0);
    const scrollHeight = (document?.documentElement?.scrollHeight || document?.body?.scrollHeight || 0);
    const clientHeight = document?.documentElement?.clientHeight || window.innerHeight;
  
  const isMobile = window.innerWidth <= 768; 

  const threshold = isMobile ? 1000 : 50;

    if ((page) * pageSize <= totalItemsCount && scrollTop + clientHeight >= scrollHeight - threshold) {
      fetchMoreData();
    }
  };
  
  useEffect(() => {
    const onScroll = () => handleMoreDataScroll();
  
    // Attach scroll event listener when component mounts
    window.addEventListener("scroll", onScroll);
  
    // Cleanup scroll event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [catalogListData, page, totalItemsCount, moredataLoading, categoryItem, moreseo_url]);  // Dependencies



  
  


const allDataRendered = moreData?.data?.length > 0 && moreData?.data?.length === totalItemsCount;

const FooterCondition = () => {
  const allDataRendered = moreData?.data?.length > 0 && moreData?.data?.length === totalItemsCount;
  if (isMobile && !loading && allDataRendered) {
    return <Footer />;
  } 
};

// useEffect(() => {
// window.addEventListener('scroll', handleScroll);

// return () => {
//     window.removeEventListener('scroll', handleScroll);
// };
// }, [filterdataLoading, filterPage, filterEpisodeItemsData]);


const subListClicked = (selectedValue, selectedKey) => {
  localStorage.setItem("dropdownChannelKey", selectedKey);
    // Find the corresponding avilFilter based on selectedValue
    const sublistItem = avilFilters.find((sub) => sub.title === selectedValue);

    if (sublistItem) {

      localStorage.setItem("SublistKey", sublistItem.key);

      localStorage.setItem("SublistTitle", sublistItem.title);
      setPage(0);
      setFromItem(0);
      setMoreData({
        data: [], // Clear data
        total_items_count: 0, // Reset total items count
        title: '', // Reset title
        layout_type: '', // Reset layout type
        filter_title: '', // Reset filter title
      });
      const pageLocation = window.location.href.split("/")[3];
      const channelName = window.location.href.split("/")[4];
      // console.log("selectedValue",selectedValue);
      // console.log("selectedKey",selectedKey);

      setselectedValue(selectedValue);
      setselectedKey(selectedKey);
      if (pageLocation === "channels") {
        navigate(
          `/${pageLocation}/${channelName}/${pageName}/${sublistItem.value}`
        );
        // window.location.reload()
      } else {
        const path = `/home/${pageName}/${sublistItem.value}`;
        navigate(path);
        // window.location.reload()
      }
    
    }
  };

  useEffect(() => {
    fetchMoreDetailsData();
    
  }, [selectedvalue,selectedkey])
  
  
// console.log("moredata",moreData);
const [imageLoadedArray, setImageLoadedArray] = useState(new Array(moreData.data.length).fill(false));
const handleImageLoad = (index) => {
  setImageLoadedArray(prevState => {
    const newState = [...prevState];
    newState[index] = true;
    return newState;
  });
};
const formattedString = moreData.title
.split('_') // Split the string by underscores
.map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
.join(' '); // Join the words back together with spaces
const handleImageClick = (ourseourl, index) => {
  setCurrentImageClick(index);
  setFromItem(0);
  setPage(0);
  // setStartCount(0);
  // fetchMoreDetailsData(); 
};


// const parameters = {
//   from: fromItem,
//   page_size: 24,
//   page: page,
//   start_count: 0
// };

// console.log("bsbsbbs", moreData)
  return (
    <div className='main-page'>
      {/* <Header /> */}
      {location.pathname === "/test" ? (
        <>
          <div className="main background-layer">
            <Container fluid className="pading-left-right-class">
              <Row>
                {movieGenresList.length
                  ? movieGenresList.map((val, i) => {
                      return renderCategoryBlockWithLayoutType(
                        (val.web_layout_type ?? val.layout_type),
                        val.display_title,
                        "vertical",
                        "high_3_4"
                      );
                    })
                  : null}
              </Row>
            </Container>
          </div>
        </>
      ) : (
        <>
        
          <div className="main background-layer">
            <Container fluid className="pading-left-right-class">
            <div className="showsFilter">

            <Row className="align-items-center">
            <Col lg="auto" md="auto" sm="auto" xs="auto">
                      <h5>{formattedString}</h5>
                    </Col>
            <Col lg="auto" md="auto" sm="auto" xs="auto">
                      {avilFilters.length > 0 && (
                        <select
                          className=""
                          onChange={(event) =>
                            subListClicked(
                              event.target.value,
                              avilFilters[0].key
                            )
                          }
                        >
                        {avilFilters && <option value="">{moreData.filter_title || "Select"} </option>  

                        }
                          {avilFilters.map((sub) => (
                            <option
                              key={sub.title}
                              value={sub.title}
                              
                              selected={
                               
                                sub.title === SublistTitle
                              }                              >
                              {sub.title.toUpperCase()}
                            </option>
                          ))}
                        </select>
                      )}
                    </Col>
            </Row>
            </div>




              {/* {!loading && moreData && (
                <div className="showsFilter">
                  <Row className="align-items-center">
                    <Col lg="auto" md="auto" sm="auto" xs="auto">
                      <h5>{formattedString}</h5>
                    </Col>
                    <Col lg="auto" md="auto" sm="auto" xs="auto">
                      {avilFilters.length > 0 && (
                        <select
                          className=""
                          onChange={(event) =>
                            subListClicked(
                              event.target.value,
                              avilFilters[0].key
                            )
                          }
                        >
                        {avilFilters && <option value="">{moreData.filter_title || "Select"} </option>  

                        }
                          {avilFilters.map((sub) => (
                            <option
                              key={sub.title}
                              value={sub.title}
                              
                              selected={
                               
                                sub.title === SublistTitle
                              }                              >
                              {sub.title.toUpperCase()}
                            </option>
                          ))}
                        </select>
                      )}
                    </Col>
                  </Row>
                </div>
              )} */}
              <>
                <br></br>
            
              </>


            
            
              {loading ?  <Loader /> :  <Row>
                    
                {moreData &&
  (moreData?.web_layout_type ?? moreData?.layout_type) === "genre" &&
  moreData?.data?.length > 0 && (
    <Container fluid className="pading-left-rightt-class">
      {moreData?.data?.map((item, index) => {
        const itemLayoutType = item?.web_layout_type ?? item?.layout_type;
        // console.log("itemLayoutTypess", itemLayoutType)
        const dynamicUrl = item.seo_url.trim();
        const ourSeoUrl = dynamicUrl.startsWith("/")
          ? dynamicUrl
          : `/${dynamicUrl}`;
        const ourSeoUrlMore = `/more${ourSeoUrl}`;

        let defaultLocalImage = "";
        let slidesPerView;
        let slidesPerGroup;
        

        let thumbnailSize = null;
        if (itemLayoutType === 'etv-exclusive_banner' || itemLayoutType === 'tv_shows_banner') {
          thumbnailSize = 'web_banner';
        } else if (['episode', 'latest_episode', 'videos', 'movie_poster', 'short', 'recipe'].includes(itemLayoutType)) {
          thumbnailSize = 'medium_16_9';
        } else if (['tv_shows', 'movies', 'top10'].includes(itemLayoutType)) {
          thumbnailSize = 'medium_3_4';
        } else {
          thumbnailSize = 'medium_3_4';
        }


        // Configure default image and slider settings

        if (itemLayoutType === "tv_shows_banner") {
          slidesPerView = 1;
          defaultLocalImage = etv_exclusive_banner_image;
        }else if (itemLayoutType === "top10" || itemLayoutType === "movie" || itemLayoutType === "movies") {
          slidesPerView = isMobile ? 2 : 6.5;
          defaultLocalImage = horizontaldefultimage;
        } else if (itemLayoutType === "tv_shows" || itemLayoutType === 'shows' || itemLayoutType === 'show') {
          slidesPerView = isMobile ? 3 : 9;
          defaultLocalImage = horizontaldefultimage;
        } else if (itemLayoutType === "etv-exclusive_banner") {
          slidesPerView = isMobile ? 1 : 2;
          defaultLocalImage = etv_exclusive_banner_image;
        } else if (["latest_episode", "short", "episode", "mini_movie_poster", "movie_poster", "videos", "promo", "mini_clips", "recipe"].includes(itemLayoutType)) {
          slidesPerView = isMobile ? 2 : 6.5;
          defaultLocalImage = verticaldefultimage;
        } else{
          slidesPerView = isMobile ? 2 : 6.5;
          defaultLocalImage = verticaldefultimage;
        }


        // if (["etv-exclusive_banner"].includes(itemLayoutType)) {
        //   slidesPerView = isMobile ? 1 : 2;
        //   defaultLocalImage = etv_exclusive_banner_image;
        // } else if (
        //   [
        //     "episode",
        //     "latest_episode",
        //     "recipe",
        //     "mini_clips",
        //     "videos",
        //     "movie_poster",
        //     "short",
        //   ].includes(itemLayoutType)
        // ) {
        //   slidesPerView = isMobile ? 2 : 6.5;
        //   defaultLocalImage = verticaldefultimage;
        // } else if (
        //   ["tv_shows", "movies", "top10", "movie"].includes(itemLayoutType)
        // ) {
        //   slidesPerView = isMobile ? 3 : 9;
        //   defaultLocalImage = horizontaldefultimage;
        // } else {
        //   slidesPerView = isMobile ? 2 : 6.5;
        //   defaultLocalImage = verticaldefultimage;
        // }

        // slidesPerGroup =
        //   itemLayoutType === "etv-exclusive_banner"
        //     ? isMobile
        //       ? 1
        //       : 2
        //     : itemLayoutType === "tv_shows_banner"
        //     ? 1
        //     : isMobile
        //     ? 2
        //     : 5;




            let sliderProps = {};
            let defultLocalimage = "";
            let styleWidth = "181"; 
            let styleHeight = "102";
            let dynamicpadding = 5;
            if (
              itemLayoutType === "movie_poster" ||
              itemLayoutType === "episode"
             // horizontal
            ) {
              styleWidth = "181";  
              styleHeight = "102";dynamicpadding = 5;
            } else if(itemLayoutType === "tv_shows") { // vertical
              styleWidth = "181";  
              styleHeight = "102";dynamicpadding = 5;
            }
            if (itemLayoutType === "etv-exclusive_banner") {
              sliderProps = twogrid;
              defultLocalimage = twoGridDefault;
            } else if (itemLayoutType === "tv_shows_banner") {
              sliderProps = getSingelgridModel(item);
              defultLocalimage = singleGridDefault;
            } else if (itemLayoutType === "top10" || itemLayoutType === "movie" || itemLayoutType === "movies") {
              sliderProps = generateBigModel(item);
              defultLocalimage = top10_image;
            } else if (itemLayoutType === "tv_shows" || itemLayoutType === 'shows' || itemLayoutType === 'show') {
              sliderProps = generateVerticalModel(item);
              defultLocalimage = horizontaldefultimage;
            } else if (
              itemLayoutType === "latest_episode" ||
              itemLayoutType === "short" ||
              itemLayoutType === "episode" ||
              itemLayoutType === "mini_movie_poster" ||
              itemLayoutType === "movie_poster" ||
              itemLayoutType === "videos" ||
              itemLayoutType === "promo" ||
              itemLayoutType === "mini_clips" ||
              itemLayoutType === "recipe" ||
              itemLayoutType === "movie_poster" 
            ) {
              sliderProps =  generateHorizontalModel(item);
              defultLocalimage = verticaldefultimage;
            } else{
              sliderProps =  generateVerticalModel(item);
              defultLocalimage = horizontaldefultimage;
            }

        return (
          <div key={index} className="first-block">
            <div className="first-slider">
              <div className="title-bar">
                <Container fluid className="pading-left-right-class">
                  <Row>
                    <Col>
                      <div className="heading-font-size">
                        <h2>{item?.display_title}</h2>
                      </div>
                    </Col>
                    {item?.catalog_list_items?.length > 7 && (
                      <Col xs="auto" style={{ textAlign: "end", padding: "0px" }}>
                        <span
                          key={index}
                          onClick={() =>
                            handleMore(
                              ourSeoUrlMore,
                              item.catalog_list_items[0],
                              "genre"
                            )
                          }
                          className="more-heading"
                        >
                          More
                          <span>
                            <FaAngleDoubleRight />
                          </span>
                        </span>
                      </Col>
                    )}
                  </Row>
                </Container>
              </div>
              <Container fluid className="left-padding-grid">
                <div
                  className={`smooth-slider ${
                    item?.display_title === "Health & Fitness"
                      ? "healthlayout"
                      : itemLayoutType
                  }`}
                >
                  {item?.catalog_list_items?.length > 0 && (
                    <Swiper
                      navigation={!isMobile}
                      modules={[Pagination, Navigation]}
                      slidesPerView={slidesPerView}
                      speed={isMobile ? 90 : 1000}
                      slidesPerGroup={slidesPerGroup}
                      spaceBetween={0}
                      breakpoints={{
                        640: {
                          slidesPerView: 3,
                          spaceBetween: 10,
                        },
                        768: {
                          slidesPerView: 4,
                          spaceBetween: 10,
                        },
                        1024: {
                          slidesPerView,
                          spaceBetween: 0,
                        },
                      }}
                    >
                      {item?.catalog_list_items?.map(
                        (innerItem, innerIndex) => {
                          const dynamicUrlInnerList = innerItem.seo_url;
                          const ourSeoUrlInnerList =
                            dynamicUrlInnerList.startsWith("/")
                              ? dynamicUrlInnerList
                              : `/${dynamicUrlInnerList}`;
                          // const imageSource =
                          //   innerItem?.thumbnails?.high_16_9?.url ||
                          //   defaultLocalImage;


                                  
const imageSource = (itemLayoutType === "etv-exclusive_banner" || itemLayoutType === "tv_shows_banner") && innerItem?.thumbnails?.[thumbnailSize]
? innerItem.thumbnails[thumbnailSize].url
: (!imageLoadedArray[index] ? defaultLocalImage
  : innerItem?.thumbnails?.[thumbnailSize]?.url || innerItem?.list_item_object?.banner_image || defaultLocalImage);


                          return (
                            <SwiperSlide key={innerIndex}>
                              <Link to={ourSeoUrlInnerList}>
                                <div
                                  style={{ cursor: "pointer" }}
                                  className="carousel-car continuewatch"
                                >
                                  <div
                                    className={`extra lock-icon-class ${
                                      !["tv_shows_banner", "etv-exclusive_banner"].includes(
                                        itemLayoutType
                                      ) && "cms-container cw-card"
                                    }`}
                                  >
                                    <div
                                      className={`thumbnailMovieCard ${
                                        !["tv_shows_banner", "etv-exclusive_banner"].includes(
                                          itemLayoutType
                                        ) && "transfimage"
                                      }`}
                                    >
                                      <img
                                        src={imageSource}
                                        alt=""
                                        width="100%"
                                        onLoad={() => handleImageLoad(index)}
                                        loading="lazy"
                                        className={`${itemLayoutType}`}
                                        onClick={() =>
                                          itemSelected(
                                            innerIndex,
                                            innerItem,
                                            moreData
                                          )
                                        }
                                      />
                                    </div>
                                    {!innerItem?.access_control?.is_free && (
                                      <div className="lock-potion-class-more"></div>
                                    )}
                                  </div>
                                </div>
                              </Link>
                            </SwiperSlide>
                          );
                        }
                      )}
                    </Swiper>
                  )}
                </div>
              </Container>
            </div>
          </div>
        );
      })}
    </Container>
  )}

              
              {moreData  &&  (moreData?.web_layout_type ?? moreData?.layout_type) !== "genre" && 
          moreData.data.length > 0 &&
          moreData.data.map((item, index) => {
            let formattedDate = '';
            if (item.release_date_string) {
              let releaseDate = new Date(item.release_date_string);
              const day = releaseDate.getDate().toString().padStart(2, "0");
              const year = (releaseDate.getFullYear() % 100).toString().padStart(2, "0");
              const monthAbbreviation = releaseDate.toLocaleString("default", { month: "short" });
              formattedDate = `${day} ${monthAbbreviation} ${year}`;
            }
        
            let displayTitle = item.title;
            if (item.release_date_string) {
              displayTitle = item.title.length > 20 ? `${item.title.substring(0, 26)}... ` : `${item.title} (${formattedDate})`;
            } else {
              displayTitle = `${item.title.substring(0, 26)}... `;  // Full title when release_date_string is empty
            }
        
            let seourlbind = `/${item.seo_url}`;
            const dynamicUrl = item.seo_url;
            const ourseourl = dynamicUrl.startsWith("/")
              ? dynamicUrl
              : `/${dynamicUrl}`;
    // console.log('moreDatasss', moreData)
    // console.log("moreData.layout_type",moreData.layout_type);
              const layoutType = moreData.web_layout_type || moreData.layout_type;
              
              let thumbnailSize = null;let defultLocalimage = "";
              let layoutclassName
              if (['video_songs','episode', 'latest_episode', 'recipe', 'mini_clips', 'videos', 'movie_poster','short','promo'].includes(layoutType)) {
                thumbnailSize = item?.thumbnails?.medium_16_9?.url;
                // console.log("nsns", thumbnailSize)
                layoutclassName = 'see-all-eight-items'
                defultLocalimage = verticaldefultimage;
              }else if (['tv_shows', 'movies', 'top10', 'movie','etv-exclusive_banner', 'tv_shows_banner', 'shows', ''].includes(layoutType)) {
                const renderedsize = item?.thumbnails?.medium_3_4?.url?.includes('default-images')
                thumbnailSize = renderedsize === true ? item?.thumbnails?.medium_16_9?.url : item?.thumbnails?.medium_3_4?.url
                layoutclassName = 'see-all-eight-items'
                if(layoutType === 'tv_shows'){
                  defultLocalimage = horizontaldefultimage;
                }else{
                  defultLocalimage = verticaldefultimage;
                }
                // console.log("ssss", item?.thumbnails)
              }else{
                thumbnailSize = item?.thumbnails?.medium_3_4?.url || item?.thumbnails?.medium_16_9?.url ;
                layoutclassName = 'see-all-eight-items'
                defultLocalimage = verticaldefultimage;
                
              }
             
              
    
    
           
    
    
            return (
              <React.Fragment key={index}>
                <Col lg={2} md={3} sm={layoutType === 'tv_shows_banner' || layoutType === "etv-exclusive_banner" || layoutType === "tv_shows" || layoutType === "shows" ? 4 : 6} xs={layoutType === 'tv_shows_banner' || layoutType === "etv-exclusive_banner" || layoutType === "tv_shows" || layoutType === "shows" ? 4 : 6} 
                className={!isMobile ? ((layoutType === 'tv_shows_banner' || layoutType === "etv-exclusive_banner" || layoutType === "tv_shows" || layoutType === "shows") ? 'see-all-eight-items' : 'see-all-six-items') : ''}
                style={{
  paddingRight:
    isMobile && 
    (layoutType === 'tv_shows_banner' ||
     layoutType === 'etv-exclusive_banner' ||
     layoutType === 'tv_shows' ||
     layoutType === 'shows') 
      ? "1px" 
      : ""
}}              
                >
    
                  <Link
                    to={ourseourl}
                  >
                       { !loading && ( <div className="carousel-car continuewatch">
                        
                      <div className="lock-icon-class cms-container cw-card">
                          <>
                        <div className="thumbnailMovieCard">
                            
<img
    src={
      imageLoadedArray[index]
        ? thumbnailSize || item?.list_item_object?.banner_image 
        : defultLocalimage
    }
    width="100%"
    onLoad={() => handleImageLoad(index)}
    onError={(e) => {
      e.target.src = defultLocalimage; // Fallback to default image if loading fails
      handleImageLoad(index); // Mark as loaded to avoid infinite reloading
    }}
    loading={!imageLoadedArray[index] ? "eager" : "lazy"} // Eager loading for default image, lazy for actual image
    onClick={() => itemSelected(index, item, moreData)}
    alt={displayTitle}
/>

{imageLoadedArray[index] && item.access_control &&
                          item.access_control.is_free ===
                                        false && (
                                        <div className="lock-postion-class"></div>
                                      )}

                            {item.media_type &&
                            acceptedMediaTypes.includes(
                            item.media_type
                            ) && (
                            <div className="iconcame">
                            <FaPlay /> 
                            </div>
                            )}
                            </div>
                      
                            { imageLoadedArray[index] && (item.show_meta_data === 'yes' && item?.media_type) && <p className="displayTitle"> {displayTitle}  </p>}
                            { imageLoadedArray[index] && (item?.sub_genres?.includes("veg") || item?.sub_genres?.includes("non_veg")) && <div className="FoodType">
                              <img
                                src={
                                  item?.sub_genres?.includes("veg")
                                    ? vegicon
                                    : nonvegicon
                                }
                                className=""
                                loading="lazy"
                                alt=""
                              />
                            </div>}
                          </>
                   
                     
                    
    
                      </div>
                    </div>
                         )}
                  </Link>
                </Col>
                {moredataLoading && 
         <div
        className="text-center mt-4"
        style={{
          position: "fixed",
          top: "60%",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 1050, 
        }}
      >
        <Spinner animation="border" variant="danger" />
      </div>
    }
                {/* Render loader after every 24 records if there are more records */}
               
              </React.Fragment>
            );
          })}
    
    
                    </Row>}

               
            
                   

            </Container>

           
          
           
          </div>
        </>
      )}

      
{/* {isMobile && <FooterCondition />} */}
      {!loading &&  <><Footer /> </>}
    </div>
  );
};

export default MoreDetails;
