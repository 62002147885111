import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
// import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import { Col, Modal, Row, Spinner } from "react-bootstrap";
import InputField from "../../Componets/inputfield";
import axios from "axios";
import { setModalClose, setModalOpen } from "../../Redux/Slice/loginModalSlice";
// import { getAnalytics, logEvent } from "firebase/analytics";
import {
  FORGOT_PASSWORD_URL,
  SIGNIN_OTP_URL,
  SIGN_IN_URL,
  auth_token,
  BASE_URL,
} from "../../services/config";
import OtpLogin from "./mobilesigninverify";
import { FaTimes } from "react-icons/fa";
import { setLoginDetails } from "../../Redux/Slice/signinDetailSlice";
import { setShowMobileRegisterModal } from "../../Redux/Slice/mobileregisterModalSlice";
import { setShowRegisterModal } from "../../Redux/Slice/registermodalSlice";
import { setMobilemodal, setUpdateMobileModalShow } from "../../Redux/Slice/updateMobileSlice";
import EmailValidation from "../emailValid/emailValidation";
export default function LoginScreen({
  showLoginOTPPopup,
  handleLoginOtpPopupClose,
  setShowLoginOTPPopup,
}) {
  const logo = "/images/win-logo.png";
  const LoginImage = "/images/login-left.png";
  const LoginPoster = "/images/loginposter.jpg";
  const OtherRegionLoginPoster = "/images/WEBLOGINOther.jpg";
  const getaccessToken = useSelector((state) => state.accesstoken_data.accessTokenData);
  const navigate = useNavigate()

  const [formData, setFormData] = useState({
    user_id: "",
    password: "",
  });

  const [showEmailValidpopup, setShowEmailValidpopup] = useState(false);

  const handleEmailPopupClose = () => {
    setShowEmailValidpopup(false);
    dispatch(setShowRegisterModal({ showRegisterModal: false }))
    dispatch(setShowMobileRegisterModal({ showMobileRegisterModal: false }))
  };

  const guestuserid = `Guest ${Math.floor(2e4 * Math.random())}`;

  const handleRegister = () => {
    dispatch(setModalClose())
    dispatch(setShowRegisterModal({ showRegisterModal: false }))
    dispatch(setShowMobileRegisterModal({ showMobileRegisterModal: true }))
    // dispatch(setModalClose())
  };

  const handleEmailRegister = () => {
    dispatch(setShowMobileRegisterModal({ showMobileRegisterModal: false }))
    dispatch(setModalClose())
    dispatch(setShowRegisterModal({ showRegisterModal: true }))
  };

  const [loading, setLoading] = useState(false);

  const [formDataErrors, setFormDataErrors] = useState({
    user_id: "",
    password: "",
  });
  const { isLoginModalOpen } = useSelector(
    (state) => state.loginModal
  );

  const regionsData = useSelector((state) => state.region.regionsData);

  const [formValid, setFormValid] = useState(false);
  const [errorMes, setErrorMes] = useState("");
  const [mobileValue, setMobileValue] = useState("");
  const [showforgotpassword, setShowforgotpassword] = useState(false);
  const [showSubscripeButton, setshowSubscripeButton] = useState(true);

  const activeCountryCode =
    regionsData?.country_code2 !== "IN" ? "email" : "mobile"; 
  const [activeTab, setActiveTab] = useState(
    regionsData?.country_code2 !== "IN" ? "email" : "mobile"
  );

  useEffect(() => {
    setActiveTab(regionsData?.country_code2 !== "IN" ? "email" : "mobile");
  }, [activeCountryCode]);
  
  // const firebaseAnalytics = getAnalytics();

  const dispatch = useDispatch();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setFormData({
      user_id: "",
      password: "",
    });
    setFormDataErrors({
      user_id: "",
      password: "",
    });
  };

  const handleLoginClose = () => {
    dispatch(setModalClose());
    setFormData({
      user_id: "",
      password: "",
    });
    setFormDataErrors({
      user_id: "",
      password: "",
    });
    setInputType(() => {
      return isValidEmail("") ? "email" : "password";
    });
  };

  const handleLoginOpen = () => {
    dispatch(setModalOpen());
  };

  const [inputType, setInputType] = useState(() => {
    return isValidEmail(formData.user_id) ? "email" : "password";
  });

  // function isValidEmail(email) {
  //   return /\S+@\S+\.\S+/.test(email);
  // }

  function isValidEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return regex.test(email)
};

  function isValidPhone(phone) {
    return /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(phone);
  }

  function isValidPassword(password) {
    const regex = /^(?=.*[0-9!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    return regex.test(password);
  }

  const handleMobileNumberInput = (event) => {
    const { name, value } = event.target;
  
    if (name === "user_id" && activeTab === "mobile") {
      const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters
      const limitedValue = numericValue.slice(0, 10); // Limit to the first 10 digits
  
      // Set only up to 10 digits in the state
      setFormData({
        ...formData,
        [name]: limitedValue,
      });
  
      let errors = { ...formDataErrors };
  
      // Validation rules for the mobile number
      if (!limitedValue.length) {
        errors.user_id = "This field can't be empty";
      } else if (!/^\d+$/.test(limitedValue)) {
        errors.user_id = "Only numbers are allowed";
      } else if (limitedValue.length !== 10) {
        errors.user_id = "Mobile number must be 10 digits";
      } else {
        errors.user_id = ""; // Clear errors if valid
        setFormValid(true); // Form is valid when 10 digits
      }
  
      setFormDataErrors(errors);
    }else {
      setFormData({
        ...formData,
        [name]: value,
      });
      const isFormValid = Object.values(formDataErrors).every(
        (error) => !error
      );
      setFormValid(!isFormValid);
    }
  };
  

  useEffect(() => {}, [formData]);

  const renderInputFields = () => {
    if (activeTab === "mobile") {
      return (
        <InputField
          type="text"
          name="user_id"
          maxLength={10}
          pattern="[0-9]*"
          value={formData.user_id}
          onChange={(e) => {
            handleChange(e);
            handleMobileNumberInput(e);
          }}
          placeholder="Mobile Number"
          error={formDataErrors.user_id}
          required
        />
      );
    } else if (activeTab === "email") {
      return (
        <>
          <InputField
            type="text"
            name="user_id"
            value={formData.user_id}
            // autoComplete="off"
            onChange={handleChange}
            placeholder="Email ID"
            error={formDataErrors.user_id}
            required
          />
          <InputField
            type="password"
            name="password"
            value={formData.password}
            autoComplete="off"
            onChange={handleChange}
            placeholder="Password"
            error={formDataErrors.password}
            required
          />
        </>
      );
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    let newUserInputType = inputType;

    if (name === "user_id") {
      // newUserInputType =
      //   isValidEmail(value) || isValidPhone(value) ? "text" : "text";

      if (isValidEmail(value)) {
        setShowforgotpassword(true);
      } else {
        setShowforgotpassword(false);
      }
    }

    setInputType(newUserInputType);
    setMobileValue(value);
    setFormData({
      ...formData,
      [name]: value,
    });

    let errors = { ...formDataErrors };

    // Validate user_id
    if (name === "user_id") {
      if (isValidEmail(value) === false && activeTab === "email") {
        errors.user_id = "Invalid email address";
      } else if (!/^\d{10}$/.test(value) && activeTab === "mobile") {
        errors.user_id = "Mobile number must be 10 digits";
      } else if (isValidPhone(value) === false && activeTab === "mobile") {
        errors.user_id = "Invalid mobile number";
      } else {
        errors.user_id = "";
      }
    }

    // Validate password
    if (name === "password") {
      errors.password = isValidPassword(value)
        ? ""
        : "Password must be at least 8 characters long and contain at least one digit or special character";
    }

    // Check if there are any errors
    const hasErrors = Object.values(errors).some((error) => error !== "");
    setFormDataErrors(errors);
    setFormValid(!hasErrors);
  };
  const loginStatus = localStorage.getItem("login_status")
  const getuserid = localStorage.getItem("user_id")
  const handleGenerateSignin = async (event) => {
    event.preventDefault();

    if (isValidEmail(formData.user_id)) {
      handleEmailSignIn(event);
      return;
    }
    setLoading(true)
    try {
      const response = await axios.post(
        SIGNIN_OTP_URL,
        {
          auth_token: auth_token,
          access_token: getaccessToken?.access_token,
          user: {
            password: "",
            user_id: `0091${formData.user_id}`,
            type: "msisdn",
            region: regionsData?.country_code2,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response?.status === 200) {
        dispatch(setModalClose()); 
        setShowLoginOTPPopup(true);
        setFormData({
          user_id: "",
          password: "",
        });
        toast.success(response?.data?.message);

        // window.sdk.trackEvent('login_success', {
        //   method: "phone number",
        //   u_id: response?.data?.data?.user_id,
        //  device_id: "NA"
        // });
      } else {
        setShowLoginOTPPopup(false);
      }
    } catch (error) {
      setErrorMes(error?.response?.data?.error?.message);
      if (error.response) {
        if (window && window.sdk && window.sdk.trackEvent) {
          window.sdk.trackEvent("login_failure", {
            method: "phone number",
            u_id:
            loginStatus === "true"
            ? getuserid
            : guestuserid,
            error_type: error?.response?.data?.error?.message,
            event_id: 6,
            platform:"web",
            device_id: "web",
            city: regionsData?.city_name,
            state: regionsData?.state,
            ip: regionsData?.ip,
            postal_code: regionsData?.postal_code,
          });
      
        } else {
          console.error('Analytics SDK is not initialized yet.');
          // Handle initialization state or queue events for later
        }

      
        console.error(
          "Server responded with an error:",
          error?.response?.data?.error?.message
        );
      }
      if (error?.response?.status === 422) {
        // console.log("nsnsnsn",  error?.response?.data?.error?.message)

        if (
          error?.response?.data?.error?.message ===
          "Your account not yet verified"
        ) {
          setShowLoginOTPPopup(true);
          setFormDataErrors({
            ...formDataErrors,
            ["user_id"]: error?.response?.data?.error?.message,
          });
        } else{
          setShowLoginOTPPopup(false);
          setFormDataErrors({
            ...formDataErrors,
            ["user_id"]: error?.response?.data?.error?.message,
          });
        }
    
      }
    }finally {
      setLoading(false); 
    }
  };

  const handleEmailSignIn = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        SIGN_IN_URL,
        {
          auth_token: auth_token,
          access_token: getaccessToken?.access_token,
          user: {
            user_id: formData.user_id,
            password: formData.password,
            type: "msisdn",
            region: regionsData?.country_code2,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response?.status === 200) {
        const userData = response?.data?.data;
        navigate('/profiles')
        localStorage.setItem("user_id", userData?.user_id);
        dispatch(setMobilemodal({mobilemodal: true}))
        window.sdk.trackEvent("login_success", {
          method: "email id",
          u_id: response?.data?.data?.user_id ?  response?.data?.data?.user_id : guestuserid,
          event_id: 5,
          platform:"web",
          device_id: "web",
          city: regionsData?.city_name,
          state: regionsData?.state,
          ip: regionsData?.ip,
          postal_code: regionsData?.postal_code,
        });

        localStorage.setItem("email", response?.data?.data?.email_id)
        dispatch(setLoginDetails({ loginDetails: response?.data?.data }));
        localStorage.setItem("loginDetailsdata", JSON.stringify(response?.data?.data))
      

        // console.log("loginwithmail", response?.data?.data)

        // logEvent(firebaseAnalytics, "login_success", {
        //   method: "email id",
        //   u_id: response?.data?.data?.user_id,
        //   device_id: "NA",
        // });
        localStorage.setItem("login_status", true);
        localStorage.setItem("user_id", response?.data?.data?.user_id);
        const newSessionId = response?.data?.data?.session;

        localStorage.setItem("session_id", newSessionId);

        await fetchActivePlans(newSessionId);
        toast.success("Login Success");

        setFormData({
          user_id: "",
          password: "",
        });

        dispatch(setModalClose())
      } else {
      }
    } catch (error) {
      if (error.response) {
        if (window && window.sdk && window.sdk.trackEvent) {
          window.sdk.trackEvent("login_failure", {
            method: "email id",
            error_type: error?.response?.data?.error?.message,
            u_id:
            loginStatus === "true"
            ? getuserid
            : guestuserid,
            event_id: 6,
            platform:"web",
            device_id: "web",
            city: regionsData?.city_name,
            state: regionsData?.state,
            ip: regionsData?.ip,
            postal_code: regionsData?.postal_code,
          });
      
        } else {
          console.error('Analytics SDK is not initialized yet.');
          // Handle initialization state or queue events for later
        }

       
        console.error("Server email login responded with an error:", error);

        if (error?.response?.status === 422) {
          const errorMessage = error?.response?.data?.error?.message;
          setFormDataErrors({
            ...formDataErrors,
            ["user_id"]: errorMessage,
          });

          setFormDataErrors({
            ...formDataErrors,
            ["password"]: errorMessage,
          });
          // toast.error(error?.response?.data?.error?.message);
          if (error?.response?.data?.error?.message === "Your account not yet verified") {
            setShowEmailValidpopup(true);
          }
          // if (error?.response?.data?.error?.message === "User doesn't exist") {
          //   setFormDataErrors({
          //     ...formDataErrors,
          //     ["user_id"]: error?.response?.data?.error?.message,
          //   });
          // }

          // if (error?.response?.data?.error?.message === "Invalid Password") {
          //   setFormDataErrors({
          //     ...formDataErrors,
          //     ["password"]: error?.response?.data?.error?.message,
          //   });
          // }
        }
      }
    }
  };
  const effectRan = useRef(false)

  const {loginDetails} = useSelector(state => state.signin_data)



  const fetchActivePlans = async (sessionID) => {
    try {
      const nextApiUrl = `${BASE_URL}users/${sessionID}/user_plans?region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${getaccessToken?.access_token}`;
      const response = await axios.get(nextApiUrl);
      const responsedata = response?.data?.data;

      if (response?.status === 200) {
        if (responsedata && responsedata.length > 0) {
          setshowSubscripeButton(false);
          localStorage.setItem("showSubscripeButton", false);
        }

        if (responsedata && responsedata.length == 0) {
          setshowSubscripeButton(true);
          localStorage.setItem("showSubscripeButton", true);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error?.message);
      // toast.error(error?.message);
    } finally {
      const showSubscripeButton = localStorage.getItem("showSubscripeButton");
      if (showSubscripeButton === "true") {
        // window.location.href = '/subscription';
        // window.location.href = "/home";
      } else {
        // window.location.reload();
      }
    }
  };

  const isEmail = isValidEmail(formData.user_id);

  const handleForgotPassword = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        FORGOT_PASSWORD_URL,
        {
          auth_token: auth_token,
          access_token: getaccessToken?.access_token,
          user: {
            user_id: formData.user_id,
            region: regionsData?.country_code2,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response?.status === 200) {
        toast.success(response?.data?.message);
      } else {
      }
    } catch (error) {
      if (error?.response) {
        toast.error(error?.response?.data?.error?.message);
        console.error("Server forgot password responded with an error:", error);

        if (error?.response?.status === 422) {
          if (
            error?.response?.data?.error?.message === "Email id not Registered"
          ) {
            setFormDataErrors({
              ...formDataErrors,
              ["user_id"]: error?.response?.data?.error?.message,
            });
          }
        }
      }
    }
  };

  const userConfig = useSelector((state) => state.user_config.userConfigData);

  // console.log("userConfig", userConfig)

  return (
    <>



{showEmailValidpopup ? <EmailValidation
          setShowEmailValidpopup={setShowEmailValidpopup}
          showEmailValidpopup={showEmailValidpopup}
          handleEmailPopupClose={handleEmailPopupClose}
        /> : 
        <>
        
        {showLoginOTPPopup ? (
          <OtpLogin
            errorMes={errorMes}
            mobileValue={mobileValue}
            userid={formData.user_id}
            setShowLoginOTPPopup={setShowLoginOTPPopup}
            formData={formData}
            showLoginOTPPopup={showLoginOTPPopup}
            handleLoginOtpPopupClose={handleLoginOtpPopupClose}
          />
        ) : (
          <>
            {isLoginModalOpen && (
              <>
                <Modal
                  show={handleLoginOpen}
                  onHide={handleLoginClose}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  size="lg"
                  className="border-class"
                >
                  <Modal.Body>
                    <div className="">
                      <Row className="mb-3 d-none">
                        <Col lg="12">
                          <div className="login-logo d-flex justify-content-between align-items-center">
                            <div className="">
                              <img src={logo} loading="lazy" alt="logo" />
                            </div>
  
                            <div className="">
                              <Link
                                onClick={() => {
                                  dispatch(setModalClose());
                                }}
                              >
                                <FaTimes className="fa-lg" />
                              </Link>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <div className=" row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 padding-rt-40">
                          <div className="d-none d-lg-block">
                          <img src={regionsData?.country_code2 === "IN" ? LoginPoster : OtherRegionLoginPoster} className="w-100 rounded" loading="lazy" alt="Login Poster" />
                          </div>
  
                          <div className="login-left-text d-none">
                            <ul>
                              <li>
                                <p>Lorem Ipsum is simply</p>
                              </li>
                              <li>
                                <p>Lorem Ipsum ***</p>
                              </li>
                              <li>
                                <p>Lorem Ipsum is simply dummy text</p>
                              </li>
                              <li>
                                <p>
                                  Lorem Ipsum is simply dummy text of the printing
                                </p>
                              </li>
                              <li>
                                <p>
                                  Lorem Ipsum is simply dummy text of the printing
                                </p>
                              </li>
                              <li>
                                <p>
                                  Lorem Ipsum is simply dummy text of the printing
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                          <Row className="mb-3">
                            <Col lg="12">
                              <div className="login-logo d-flex justify-content-end align-items-center">
                                <div className="d-none">
                                  <img src={logo} alt='logo' />
                                </div>
  
                                <div className="">
                                  <Link
                                    onClick={() => {
                                      dispatch(setModalClose());
                                    }}
                                  >
                                    <FaTimes className="fa-lg" />
                                  </Link>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <div className="login-left-styles">
                            <h6 className="text-center">Login</h6>
                            <p className="text-center">
                              Enjoy ETV Win with More Benefits and Features
                            </p>
                          </div>
  
                          {regionsData?.country_code2 === "IN" && (
                            <div className="loginTabs">
                              <button
                                className={`btn ${
                                  activeTab === "mobile" ? "active" : ""
                                }`}
                                onClick={() => handleTabChange("mobile")}
                              >
                                Mobile Number
                              </button>
                              <button
                                className={`btn ${
                                  activeTab === "email" ? "active" : ""
                                }`}
                                onClick={() => handleTabChange("email")}
                              >
                                Email
                              </button>
                            </div>
                          )}
  
                          <div className="form-style">
                            <form
                              onSubmit={
                                isEmail ? handleEmailSignIn : handleGenerateSignin
                              }
                            >
                              {renderInputFields()}
                              <button
                                className="next-button-color"
                                disabled={!formValid}
                              >
                              
                                {loading ? <Spinner /> : 'Next'}
                              </button>
                            </form>
                            <div className="registe-color">
                              <p>
                                {activeTab === "email" && 
                                <button
                                  className="regist-color"
                                  disabled={!showforgotpassword}
                                  onClick={handleForgotPassword}
                                >
                                  Forgot Password
                                </button>
                                }
                                &nbsp; New to Us? &nbsp;
                                <Link
                                  className="registenowlink"
                                  onClick={
                                    regionsData?.country_code2 !== "IN"
                                      ? handleEmailRegister
                                      : handleRegister
                                  } 
                                >
                                  Register Now
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </>
            )}
          </>
        )}
        </>
        }

    </>
  );
}
