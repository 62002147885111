import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showAutopayModal: false,
};

export const cancelAutopaySlice = createSlice({
  name: "autopaymodal",
  initialState,
  reducers: {
    setShowAutopayModal: (state, action) => {
        state.showAutopayModal = action.payload.showAutopayModal  
      },
  },
});

export const {setShowAutopayModal, setUserPlansData} = cancelAutopaySlice.actions;

export default cancelAutopaySlice.reducer;